import { useCallback } from "react";
import { enqueueSnackbar } from "notistack";
import { useLocation } from "react-router";
import { copyToClipboard as copyToClipboard_ } from "@app/util/Utils";
const useCopyLinkToCurrentPage = () => {
    const location = useLocation();
    const copyToClipboard = useCallback(() => {
        let { pathname } = location;
        pathname = `/a${pathname.replace("/gmail-portal", "")}`;
        const url = `${window.location.protocol}//${window.location.host}${[[pathname, location.search].filter((x) => x).join("?"), location.hash].filter((x) => x).join("#")}`;
        copyToClipboard_(url);
        enqueueSnackbar("Copied link to clipboard", { variant: "success" });
    }, [location]);
    return copyToClipboard;
};
export default useCopyLinkToCurrentPage;
