var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { PowerSettingsNew, Web } from "@mui/icons-material";
import { Drawer, List, ListSubheader, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import CoreLink from "@app/common/CoreLink";
import MaterialIcon from "@app/common/MaterialIcon";
import theme from "@app/common/theme";
import useSession from "@app/hooks/useSession";
import useSize from "@app/hooks/useSize";
import { useGetSidebar } from "@app/orval/api/sidebar";
import { useListStarredViews } from "@app/orval/api/views";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import { isChromeExtension, isLocal } from "@app/util/Utils";
const RouteListItem = (props) => {
    var _a;
    const { children } = props, other = __rest(props, ["children"]);
    return (React.createElement(ListItemButton, Object.assign({ to: other.to, href: other.href, component: other.component }, other.ListItemButtonProps, { style: Object.assign({ color: "unset", paddingTop: "4px", paddingBottom: "4px" }, (_a = props.ListItemButtonProps) === null || _a === void 0 ? void 0 : _a.style) }), children));
};
const SidebarSection = (props) => {
    const { items, padding } = props;
    const session = useSession();
    if (!(session === null || session === void 0 ? void 0 : session.viewingAsUser)) {
        return null;
    }
    return (React.createElement(React.Fragment, null, items.map((item) => (React.createElement(React.Fragment, null,
        item.openInNewTab ? (React.createElement(RouteListItem, { key: item.path, component: CoreLink, href: item.path, ListItemButtonProps: {
                style: {
                    paddingLeft: `${padding}px`,
                },
            } },
            React.createElement(ListItemIcon, { style: { minWidth: "32px" } }, item.iconName ? React.createElement(MaterialIcon, { name: item.iconName }) : null),
            React.createElement(ListItemText, { primary: item.name }))) : (React.createElement(RouteListItem, { key: item.path, component: RouterLink, to: item.path, ListItemButtonProps: {
                style: {
                    paddingLeft: `${padding}px`,
                },
            } },
            React.createElement(ListItemIcon, { style: { minWidth: "32px" } }, item.iconName ? React.createElement(MaterialIcon, { name: item.iconName }) : null),
            React.createElement(ListItemText, { primary: item.name }))),
        item.children && React.createElement(SidebarSection, { items: item.children, padding: padding + 12 }))))));
};
const Sidebar = (props) => {
    var _a, _b, _c, _d, _e;
    const { open } = props;
    const isSmall = useSize().isDown("sm");
    const sidebarWidth = open ? (!isSmall ? 240 : "100%") : 0;
    const session = useSession();
    const listStarredViewsApi = useListStarredViews({ user_uid: (_b = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : undefined }, Object.assign(Object.assign({}, INFINITE_CACHE_PARAMS), { query: Object.assign(Object.assign({}, INFINITE_CACHE_PARAMS.query), { enabled: !!((_c = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.uid) }) }));
    const starredViews = React.useMemo(() => {
        var _a, _b;
        return ((_b = (_a = listStarredViewsApi.data) === null || _a === void 0 ? void 0 : _a.data.items.sort((a, b) => {
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        })) !== null && _b !== void 0 ? _b : []);
    }, [(_d = listStarredViewsApi.data) === null || _d === void 0 ? void 0 : _d.data]);
    const getSidebarApi = useGetSidebar(INFINITE_CACHE_PARAMS);
    const sidebarConfig = React.useMemo(() => {
        var _a, _b;
        const baseConfig = (_b = (_a = getSidebarApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
        return baseConfig
            ? Object.assign(Object.assign({}, baseConfig), { sections: baseConfig.sections.map((section) => {
                    if (section.name === "CRM") {
                        return Object.assign(Object.assign({}, section), { items: [
                                ...section.items,
                                ...starredViews.map((view) => ({
                                    iconName: "star",
                                    name: view.name,
                                    path: `/views/${view.uid}`,
                                })),
                            ] });
                    }
                    return section;
                }) }) : null;
    }, [(_e = getSidebarApi.data) === null || _e === void 0 ? void 0 : _e.data, starredViews]);
    const sellerPortalLink = React.useMemo(() => {
        if (isChromeExtension) {
            return "https://sellers.dash.premierbrokeragesystem.com";
        }
        if (isLocal) {
            return `${new URL(window.location.href).protocol}//${new URL(window.location.href).host}/seller-portal/`;
        }
        return `${new URL(window.location.href).protocol}//sellers.${new URL(window.location.href).host}`;
    }, []);
    if (!(session === null || session === void 0 ? void 0 : session.viewingAsUser)) {
        return null;
    }
    return (React.createElement(Drawer, { variant: "permanent", PaperProps: { elevation: 4 }, sx: {
            width: sidebarWidth,
            flexShrink: 5,
            whiteSpace: "nowrap",
            "& .MuiDrawer-paper": {
                width: sidebarWidth,
                position: "relative",
                backgroundColor: "#d1d3d4",
                color: "#000000",
                overflowY: "auto",
                transition: theme.transitions.create("width", {
                    easing: theme.transitions.easing.sharp,
                    duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
                }),
                boxShadow: "2px 0 5px rgba(0,0,0,0.1)",
            },
            "& .MuiListSubheader-root": {
                background: "#a7bbc9",
                color: "#000000",
                fontSize: "0.875rem",
                fontWeight: "bold",
                padding: "2px 8px",
                borderRadius: "8px",
                margin: "2px 0",
                borderWidth: "thin",
            },
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
            }),
            overflowX: "hidden",
            height: "100%",
        } }, sidebarConfig === null || sidebarConfig === void 0 ? void 0 :
        sidebarConfig.sections.map((section) => {
            return (React.createElement(List, { key: section.name, subheader: React.createElement(ListSubheader, null, section.name) },
                React.createElement(SidebarSection, { items: section.items, padding: 10 })));
        }),
        session.viewingAsUser.isInternal && (React.createElement(List, null,
            React.createElement(RouteListItem, { component: CoreLink, href: sellerPortalLink },
                React.createElement(ListItemIcon, null,
                    React.createElement(Web, null)),
                React.createElement(ListItemText, { primary: "Seller Portal" })))),
        !isChromeExtension && (React.createElement(List, null,
            React.createElement(RouteListItem, { component: CoreLink, href: "/logout/" },
                React.createElement(ListItemIcon, null,
                    React.createElement(PowerSettingsNew, null)),
                React.createElement(ListItemText, { primary: `${session.viewingAsUser.uid ? "Logout" : "Login"}` }))))));
};
export default Sidebar;
