var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import dayjs from "dayjs";
import memoize from "fast-memoize";
import fontColorContrast from "font-color-contrast";
import parsePhoneNumber, { isValidPhoneNumber as isValidPhoneNumber_ } from "libphonenumber-js";
import cloneDeep from "lodash/cloneDeep";
import isObject from "lodash/isObject";
import numeral from "numeral";
import dayjs_ from "@app/common/dayjs";
import AppConfig from "./AppConfig";
import config from "../../../../config.json";
/**
 * Copies text to the user's clipboard
 *
 * @param text The text to copy
 */
export const copyToClipboard = (text) => {
    const listener = (e) => {
        e.clipboardData.setData("text/html", text);
        e.clipboardData.setData("text/plain", text);
        e.preventDefault();
    };
    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);
};
/** Converts a base64 string to an array buffer */
export const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
};
/** Converts a base64 string to a file */
export const base64ToFile = (base64, filename, mimeType) => {
    // Decode base64 string to binary data
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    // Convert binary string to Uint8Array
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    // Create a Blob object from the Uint8Array
    const blob = new Blob([bytes], { type: mimeType });
    // Create a File object from the Blob
    return new File([blob], filename, { type: mimeType });
};
/** Useful for sorting number columns with MUI-DataTables */
export const numericSortCompare = (order) => (a, b) => {
    var _a, _b;
    const aVal = (_a = numeral(a.data).value()) !== null && _a !== void 0 ? _a : null;
    const bVal = (_b = numeral(b.data).value()) !== null && _b !== void 0 ? _b : null;
    if (aVal == null && bVal == null)
        return 0;
    if (aVal == null && bVal != null)
        return -1;
    if (aVal != null && bVal == null)
        return 1;
    if (aVal < bVal)
        return -1 * (order === "asc" ? 1 : -1);
    if (aVal > bVal)
        return 1 * (order === "asc" ? 1 : -1);
    if (a.data < b.data)
        return -1 * (order === "asc" ? 1 : -1);
    if (a.data > b.data)
        return 1 * (order === "asc" ? 1 : -1);
    return 0;
};
/** Converts a hex color string to RGB components */
export const hexToRGB = (hex) => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const hexSanitized = hex.replace(shorthandRegex, (_, r, g, b) => r + r + g + g + b + b);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexSanitized);
    return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        }
        : null;
};
// CREDIT: https://stackoverflow.com/questions/16194730/seeking-a-statistical-javascript-function-to-return-p-value-from-a-z-score
/**
 * Gets a [p-value](https://en.wikipedia.org/wiki/P-value) from a [z-score](https://en.wikipedia.org/wiki/Standard_score)
 *
 * CREDIT: https://stackoverflow.com/questions/16194730/seeking-a-statistical-javascript-function-to-return-p-value-from-a-z-score
 */
export const getPValueFromZScore = (z) => {
    // z == number of standard deviations from the mean
    // if z is greater than 6.5 standard deviations from the mean
    // the number of significant digits will be outside of a reasonable
    // range
    if (z < -6.5) {
        return 0.0;
    }
    if (z > 6.5) {
        return 1.0;
    }
    let factK = 1;
    let sum = 0;
    let term = 1;
    let k = 0;
    const loopStop = Math.exp(-23);
    while (Math.abs(term) > loopStop) {
        term = (((0.3989422804 * Math.pow((-1), k) * Math.pow(z, k)) / (2 * k + 1) / Math.pow(2, k)) * Math.pow(z, (k + 1))) / factK;
        sum += term;
        k += 1;
        factK *= k;
    }
    sum += 0.5;
    return sum;
};
/** Formats a phone number */
export const formatPhoneNumber = (
/** The phone number, in any parseable format */
phoneNumberString, options) => {
    var _a, _b, _c;
    const country = (_a = options === null || options === void 0 ? void 0 : options.country) !== null && _a !== void 0 ? _a : "US";
    const format = (_b = options === null || options === void 0 ? void 0 : options.format) !== null && _b !== void 0 ? _b : "national";
    const failSilently = (_c = options === null || options === void 0 ? void 0 : options.failSilently) !== null && _c !== void 0 ? _c : false;
    try {
        const parsedNumber = parsePhoneNumber(phoneNumberString, country);
        if (format === "international") {
            return parsedNumber === null || parsedNumber === void 0 ? void 0 : parsedNumber.formatInternational().replace(/\s/g, "");
        }
        return parsedNumber === null || parsedNumber === void 0 ? void 0 : parsedNumber.formatNational();
    }
    catch (err) {
        console.warn(`Unable to parse phone number ${phoneNumberString}`, err);
        if (failSilently && String(err).includes("ParseError")) {
            return null;
        }
        throw err;
    }
};
export const formatPhoneNumberMEMOIZED = memoize(formatPhoneNumber);
export const isValidPhoneNumber = (phoneNumber, options) => {
    var _a, _b;
    const parsed = formatPhoneNumber(phoneNumber, {
        country: (_a = options === null || options === void 0 ? void 0 : options.country) !== null && _a !== void 0 ? _a : "US",
        format: "international",
        failSilently: true,
    });
    return parsed != null && isValidPhoneNumber_(parsed, (_b = options === null || options === void 0 ? void 0 : options.country) !== null && _b !== void 0 ? _b : "US");
};
export const isValidPhoneNumberMEMOIZED = memoize(isValidPhoneNumber);
export const googleMapsAPIKey = "AIzaSyAFne4vMqOZBn9yYm_u04sqpX6JZQ_8d6Y";
export const arcGISWebMapID = "f5f0c2242f10412496262ba8a018308d";
/**
 * Base url for arcgis map. Needs a property address added to end of string
 */
export const arcGISURL = `https://www.arcgis.com/home/webmap/viewer.html?webmap=${arcGISWebMapID}&find=`;
/**
 * Converts a string to title case.
 *
 * Ex: "hello world" becomes "Hello World"
 * Ex: "HELLO-WORLD" becomes "Hello-World"
 *
 * @param str the input string
 * @returns the title-cased string
 */
export const toTitleCase = (str) => str.replace(/\w*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
export const getStreakLinkForBox = (boxKey) => `https://streak.com/a/boxes/${boxKey}`;
export const getStreakLinkForPipeline = (pipelineKey) => `https://streak.com/a/pipelines/${pipelineKey}`;
/** returns a link to the zillow listing for a given address */
export const getZillowLinkForAddress = (address) => `https://www.zillow.com/homes/${address}`;
/**
 * Gets a human-readable string error message from an Error or Object.
 * Useful for overriding the behavior or console.error to play nice with Sentry, for instance.
 * @param error the error to parse
 * @returns the sanitized error message
 */
export const getReadableErrorMessage = (error) => {
    var _a, _b;
    let isJSON = false;
    if (error === null || error === void 0 ? void 0 : error.message) {
        try {
            error = JSON.parse(error.message);
            isJSON = true;
        }
        catch (err) {
            // error is not JSON
        }
    }
    else {
        try {
            JSON.stringify(error);
            isJSON = true;
        }
        catch (err) {
            // error is not JSON
        }
    }
    if (isJSON && Object.keys(error).length > 0) {
        return JSON.stringify(((_a = error.result) === null || _a === void 0 ? void 0 : _a.data) || ((_b = error.response) === null || _b === void 0 ? void 0 : _b.data) || error.data || error, null, 2);
    }
    return String(error.message || error);
};
/**
 * Shifts focus from one DOM input element to the next or previous
 * @param node current DOM element
 * @param direction 'next' or 'previous', which DOM element to shift focus to
 */
export const focus = (node, direction) => {
    const inputs = document.querySelectorAll("input");
    const currentIndex = [...inputs].findIndex((element) => node.isEqualNode(element));
    const targetIndex = (currentIndex + (direction === "next" ? 1 : -1)) % inputs.length;
    inputs[targetIndex].focus();
};
export const defaultUserIcon = "https://i.stack.imgur.com/34AD2.jpg";
/**
 * Gets the ascii content from a File object
 */
export const getAsciiContent = (file, completionHandler) => {
    const reader = new FileReader();
    reader.onload = () => {
        completionHandler(reader.result);
    };
    reader.readAsText(file);
};
export const fileToBase64 = (file) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            // @ts-expect-error object is possibly null
            resolve(reader.result.split(",")[1]);
        };
        reader.onerror = (error) => {
            reject(error);
        };
    });
});
/** Download contents as a file
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
export const downloadBlob = (
/** A string of base64-encoded data */
content, 
/** File name of the output file */
filename, 
/** MIME type of the file */
contentType, target) => {
    // Create a blob
    const decoded = atob(content);
    const uint8 = new Uint8Array(decoded.length);
    for (let i = 0; i < uint8.length; ++i) {
        uint8[i] = decoded.charCodeAt(i);
    }
    const blob = new Blob([uint8], { type: contentType });
    const url = URL.createObjectURL(blob);
    // Create a link to download it
    const pom = document.createElement("a");
    pom.href = url;
    if (target) {
        pom.target = target;
    }
    pom.setAttribute("download", filename);
    pom.click();
};
export const fileContentToURL = (
/** A string of base64-encoded data */
content, 
/** MIME type of the file */
contentType) => {
    // Create a blob
    const decoded = atob(content);
    const uint8 = new Uint8Array(decoded.length);
    for (let i = 0; i < uint8.length; ++i) {
        uint8[i] = decoded.charCodeAt(i);
    }
    const blob = new Blob([uint8], { type: contentType });
    const url = URL.createObjectURL(blob);
    return url;
};
/** calls the click() function on an HTML element with the given id */
export const onUploadButtonClick = (id) => () => {
    document.getElementById(id).click();
};
/** Bridge Datasets excluding `Zillow` */
console.log("CONFIG", config);
export const bridgePropertyDatasets = config.bridge_datasets.filter((dataset) => {
    return dataset.toLowerCase().trim() !== "zillow";
});
/**
 * given a latitude and distance, calculates the range of lat/lng values
 */
export const getLatLng = (latitude, distance) => {
    if (distance == null) {
        return null;
    }
    const earthRadius = 3960;
    const degreesToRadians = Math.PI / 180;
    const radiansToDegrees = 180 / Math.PI;
    const latDiff = (distance / earthRadius) * radiansToDegrees;
    const lngDiff = (distance / (earthRadius * Math.cos(latitude * degreesToRadians))) * radiansToDegrees;
    return { latDiff, lngDiff };
};
/** MLS Statuses for active listings */
export const activeStatuses = config.mls_active_statuses;
/** MLS Statuses for pending listings */
export const pendingStatuses = config.mls_pending_statuses;
/** MLS Statuses for closed listings */
export const closedStatuses = config.mls_closed_statuses;
/** Active, Pending, and Closed MLS statuses */
export const allMlsStatuses = [...activeStatuses, ...pendingStatuses, ...closedStatuses];
/**
 * Creates Bridge Query string based on given parameters
 * @param queryParams query params formatted for MLS Grid. Ex: {close_date__lte: "2022-10-10"}
 * @param distance distance in miles
 * @param latitude
 * @param longitude
 * @returns Bridge Query String. Ex: "$filter=PropertyType eq 'Residential' and YearBuilt ge 1999&$top=200"
 */
export const getBridgeQuery = (queryParams, distance, latitude, longitude) => {
    const queryArr = [];
    const quotedParams = ["PropertyType", "PropertySubType"];
    Object.keys(queryParams).forEach((key) => {
        var _a;
        if (key === "statuses") {
            const statusQueryParams = [];
            const statuses = ((_a = queryParams[key]) !== null && _a !== void 0 ? _a : "").split(",");
            statuses.forEach((status) => {
                statusQueryParams.push(`tolower(MlsStatus) eq '${status.toLowerCase().trim()}'`);
            });
            queryArr.push(`(${statusQueryParams.join(" or ")})`);
        }
        else {
            let bridgeValue = queryParams[key];
            if (bridgeValue) {
                let bridgeName = key.split("__")[0];
                let operator = "eq";
                if (key.endsWith("__lte")) {
                    operator = "le";
                }
                else if (key.endsWith("__gte")) {
                    operator = "ge";
                }
                else if (key.endsWith("__lt")) {
                    operator = "lt";
                }
                else if (key.endsWith("__gt")) {
                    operator = "gt";
                }
                if (bridgeName.includes("bathrooms_full")) {
                    bridgeName = bridgeName.replace("bathrooms_full", "BathroomsFull");
                }
                else if (bridgeName === "property_sub_type") {
                    bridgeName = "PropertySubType";
                }
                else {
                    const firstName = bridgeName.split("_")[0].charAt(0).toUpperCase() + bridgeName.split("_")[0].slice(1);
                    const secondName = bridgeName.includes("_")
                        ? bridgeName.split("_")[1].charAt(0).toUpperCase() + bridgeName.split("_")[1].slice(1)
                        : "";
                    bridgeName = firstName + secondName;
                }
                if (quotedParams.includes(bridgeName)) {
                    bridgeValue = `'${bridgeValue}'`;
                }
                if (bridgeName === "PropertySubType" && queryParams[key].toLowerCase().trim() === "manufactured") {
                    queryArr.push(`contains(tolower(${bridgeName}), ${bridgeValue.toLowerCase().trim()})`);
                }
                else {
                    queryArr.push(`${bridgeName} ${operator} ${bridgeValue}`);
                }
            }
        }
    });
    if (latitude !== null && longitude !== null) {
        const latLngDiff = getLatLng(latitude, distance);
        queryArr.push(`Latitude ge ${latitude - latLngDiff.latDiff}`);
        queryArr.push(`Latitude le ${latitude + latLngDiff.latDiff}`);
        queryArr.push(`Longitude ge ${longitude - latLngDiff.lngDiff}`);
        queryArr.push(`Longitude le ${longitude + latLngDiff.lngDiff}`);
    }
    return queryArr.length > 0 ? `${queryArr.join(" and ")}` : "";
};
export const getSparkQuery = (queryParams, distance, latitude, longitude) => {
    const queryArr = [];
    const quotedParams = ["PropertyType", "PropertySubType"];
    const sparkValueMapping = {
        Residential: "A",
    };
    Object.keys(queryParams).forEach((key) => {
        var _a;
        if (key === "statuses") {
            const statusQueryParams = [];
            const statuses = ((_a = queryParams[key]) !== null && _a !== void 0 ? _a : "").split(",");
            statuses.forEach((status) => {
                statusQueryParams.push(`StandardStatus Eq '${status.trim()}'`);
            });
            statusQueryParams.join(" OR ");
            queryArr.push(`(${statusQueryParams})`);
        }
        else {
            let sparkValue = queryParams[key];
            if (sparkValue) {
                let sparkName = key.split("__")[0];
                let operator = "Eq";
                if (key.endsWith("__lte")) {
                    operator = "Le";
                }
                else if (key.endsWith("__gte")) {
                    operator = "Ge";
                }
                else if (key.endsWith("__lt")) {
                    operator = "Lt";
                }
                else if (key.endsWith("__gt")) {
                    operator = "Gt";
                }
                if (sparkName.includes("bathrooms_full")) {
                    sparkName = sparkName.replace("bathrooms_full", "BathsFull");
                }
                else if (sparkName.includes("bedrooms_total")) {
                    sparkName = sparkName.replace("bedrooms_total", "BedsTotal");
                }
                else if (sparkName === "property_sub_type") {
                    sparkName = "PropertySubType";
                }
                else {
                    const firstName = sparkName.split("_")[0].charAt(0).toUpperCase() + sparkName.split("_")[0].slice(1);
                    const secondName = sparkName.includes("_")
                        ? sparkName.split("_")[1].charAt(0).toUpperCase() + sparkName.split("_")[1].slice(1)
                        : "";
                    sparkName = firstName + secondName;
                }
                if (sparkName === "PropertyType") {
                    sparkValue = sparkValueMapping[sparkValue];
                }
                if (quotedParams.includes(sparkName)) {
                    sparkValue = `'${sparkValue}'`;
                }
                if (sparkName === "PropertySubType" && queryParams[key].toLowerCase().trim() === "manufactured") {
                    queryArr.push(`tolower(${sparkName}) ${operator} tolower(${sparkValue.trim()})`);
                }
                else {
                    queryArr.push(`${sparkName} ${operator} ${sparkValue}`);
                }
            }
        }
    });
    if (latitude && longitude) {
        const latLngDiff = getLatLng(latitude, distance);
        queryArr.push(`Latitude Ge ${latitude - latLngDiff.latDiff}`);
        queryArr.push(`Latitude Le ${latitude + latLngDiff.latDiff}`);
        queryArr.push(`Longitude Ge ${longitude - latLngDiff.lngDiff}`);
        queryArr.push(`Longitude Le ${longitude + latLngDiff.lngDiff}`);
    }
    return queryArr.join(" AND ");
};
/**
 * Given an address (# streetName streetSuffix, City, State Zip) returns the lowercase # streetname
 * */
export const simplifyAddress = (address) => {
    let simplifiedAddress = address.toLowerCase().trim();
    const streetSuffixes = [
        "street",
        "point",
        "road",
        "circle",
        "drive",
        "court",
        "lane",
        "place",
        "mount",
        "trail",
        "st",
        "pt",
        "rd",
        "ln",
        "cir",
        "dr",
        "ct",
        "pl",
        "mt",
        "trl",
    ];
    streetSuffixes.forEach((suffix) => {
        simplifiedAddress = simplifiedAddress.replace(suffix, "");
    });
    return simplifiedAddress.split(",")[0].replace(/[^0-9A-Za-z]/g, "");
};
/**
 *
 * converts any values that are justs asterisks to null
 * Some MLS fields use a string of asterisks instead of null for some values
 *
 * */
export const convertAsterisksToNull = (data) => {
    const convertedData = {};
    Object.keys(data).forEach((key) => {
        let value = data[key];
        if (typeof data[key] === "string" &&
            data[key].split("").length ===
                data[key].split("").filter((char) => {
                    return char === "*";
                }).length) {
            value = null;
        }
        convertedData[key] = value;
    });
    return convertedData;
};
export const getBrokerageFeeForMarket = (market) => {
    var _a;
    const defaultFee = config.brokerage_fee;
    if (market == null || market.trim() === "") {
        return defaultFee;
    }
    return (_a = config.market_brokerage_fees[market.toLowerCase().trim()]) !== null && _a !== void 0 ? _a : defaultFee;
};
/** list of valid markets for dash daily */
export const dashDailyMarkets = config.dash_daily_markets;
/** list of markets for the Streak Changes Reports */
export const streakChangesReportMarkets = config.streak_changes_report_markets;
export const formatTime = (value, format) => {
    return value != null && value !== "" && dayjs_(value.split(".")[0]).isValid()
        ? dayjs_(value.split(".")[0]).format(format)
        : (value !== null && value !== void 0 ? value : "");
};
export const formatTimeMemoized = memoize(formatTime);
export const formatNumber = (value, format, options) => {
    var _a;
    const multiplyPercentagesBy100 = (_a = options === null || options === void 0 ? void 0 : options.multiplyPercentagesBy100) !== null && _a !== void 0 ? _a : false;
    if (value == null || value === "") {
        return "";
    }
    if (format.includes("%") && multiplyPercentagesBy100) {
        return numeral(numeral(value).value() * 100).format(format);
    }
    return numeral(value).format(format);
};
export const formatNumberMemoized = memoize(formatNumber);
export const formatFormula = (value, format) => {
    if (value == null || value === "") {
        return "";
    }
    if (format.includes("0")) {
        return numeral(value).format(format);
    }
    return dayjs_(value).format(format);
};
export const formatFormulaMemoized = memoize(formatFormula);
export const getInitials = (firstName, lastName) => {
    return [firstName, lastName]
        .filter((o) => o === null || o === void 0 ? void 0 : o.trim())
        .map((o) => o.trim().substring(0, 1).toUpperCase())
        .join("");
};
export const getInitialsMemoized = memoize(getInitials);
export const getFullName = (firstName, lastName) => {
    return [firstName, lastName]
        .filter((o) => o === null || o === void 0 ? void 0 : o.trim())
        .map((o) => o.trim())
        .join(" ");
};
export const getFullNameMemoized = memoize(getFullName);
/**
 *
 * @param user
 * @returns Users' full name (FirstName LastName)
 */
export const getUserName = (user) => {
    if (user == null) {
        return "";
    }
    const fullName = `${user.first_name} ${user.last_name}`;
    return fullName;
};
/**
 * Formats a value based on the output format defined for the field
 * @param field
 * @param value
 * @returns
 */
export const formatFieldValue = (field, value, params) => {
    var _a, _b;
    const multiplyPercentagesBy100 = (_a = params === null || params === void 0 ? void 0 : params.multiplyPercentagesBy100) !== null && _a !== void 0 ? _a : true;
    let value_ = value;
    if (field.field_type === "formula") {
        value_ = (_b = value_ === null || value_ === void 0 ? void 0 : value_.value) !== null && _b !== void 0 ? _b : "";
    }
    else {
        value_ = value !== null && value !== void 0 ? value : "";
    }
    let formattedValue;
    if (field.format && ["date", "datetime", "time"].includes(field.field_type)) {
        if (String(value_).includes("T") || String(value_).includes("Z")) {
            formattedValue =
                value_ != null && value_ !== "" && dayjs_(value_).isValid()
                    ? dayjs_(value_).format(field.format.replaceAll("y", "Y").replace("/d/", "/D/"))
                    : (value_ !== null && value_ !== void 0 ? value_ : "");
        }
        else {
            formattedValue =
                value_ != null && value_ !== "" && dayjs_(value_).isValid()
                    ? dayjs_.tz(value_, "America/New_York").format(field.format.replaceAll("y", "Y").replace("/d/", "/D/"))
                    : (value_ !== null && value_ !== void 0 ? value_ : "");
        }
    }
    else if (field.format && field.field_type === "time") {
        // NOTE: Are we defaulting time fields to 2022?
        const currentYear = dayjs_().format("YYYY");
        formattedValue =
            value_ != null && dayjs_(`1/1/${currentYear} ${value_}`).isValid()
                ? dayjs_(`1/1/${currentYear} ${value_}`).format(field.format.replaceAll("y", "Y").replace("/d/", "/D/"))
                : (value_ !== null && value_ !== void 0 ? value_ : "");
    }
    else if (field.format && field.field_type === "number") {
        const isPercent = field.format.includes("%");
        formattedValue =
            value_ == null || value_ === ""
                ? ""
                : numeral(value_ * (isPercent && multiplyPercentagesBy100 ? 100 : 1)).format(field.format);
    }
    else if (field.format && field.field_type === "formula" && value_ != null && value_ !== "") {
        const isPercent = field.format.includes("%");
        if (field.format.includes("0")) {
            formattedValue = numeral(value_ * (isPercent && multiplyPercentagesBy100 ? 100 : 1)).format(field.format);
        }
        else if (String(value_).includes("T") || String(value_).includes("Z")) {
            formattedValue =
                value_ != null && value_ !== "" && dayjs_(value_).isValid()
                    ? dayjs(value_).format(field.format.replaceAll("y", "Y").replace("/d/", "/D/"))
                    : (value_ !== null && value_ !== void 0 ? value_ : "");
        }
        else {
            formattedValue =
                value_ != null && value_ !== "" && dayjs_(value_).isValid()
                    ? dayjs_.tz(value_, "America/New_York").format(field.format.replaceAll("y", "Y").replace("/d/", "/D/"))
                    : (value_ !== null && value_ !== void 0 ? value_ : "");
        }
    }
    else if (field.field_type === "text") {
        formattedValue = value_;
    }
    else {
        formattedValue = String(value_);
    }
    return formattedValue;
};
export const DATEJS_FORMAT_TO_DAYJS = {
    yyyy: "YYYY",
    yy: "YY",
    dd: "DD",
    d: "D",
    tt: "A",
};
/**
 * Convert a Date format string to a valid Dayjs format string
 * Ex: Converts MM/dd/yyyy to MM/DD/YYYY or yyyy-MM-dd h:mm tt to YYYY-MM-DD h:mm A
 * @param format
 * TODO: this will not work perfectly for all formats. It would incorrectly convert yyyy-MM-ddd to YYYY-MM-DDD
   Not an issue with current field definitions, but may need to be revisited
 */
export const convertDateFormatToDayjs = (format) => {
    let dayjsFormat = format;
    Object.keys(DATEJS_FORMAT_TO_DAYJS).forEach((datejsFormatPiece) => {
        dayjsFormat = dayjsFormat.replace(datejsFormatPiece, DATEJS_FORMAT_TO_DAYJS[datejsFormatPiece]);
    });
    return dayjsFormat;
};
/**
 * Format a date using DayJS
 * @param value
 * @param format
 * @returns
 */
export const formatDate_Dayjs = (value, format) => {
    if (value == null || value === "") {
        return value !== null && value !== void 0 ? value : "";
    }
    const isTimeFormat = format.includes("h") || format.includes("m") || format.includes("s");
    if (isTimeFormat) {
        return dayjs_(value).format(format.replace("yyyy", "YYYY"));
    }
    return dayjs_(value).utc().format(format.replace("yyyy", "YYYY"));
};
/**
 * Format a date using DayJS
 */
export const formatDateMemoized_Dayjs = memoize(formatDate_Dayjs);
/**
 * Formats a date to a local time in the given timezone
 *
 * @param date The date to format
 * @param params The format and timezone to use
 * @returns The formatted date
 */
export const formatDateInTimezone = (date, params) => {
    var _a, _b;
    const format = (_a = params === null || params === void 0 ? void 0 : params.format) !== null && _a !== void 0 ? _a : "YYYY-MM-DD[T]HH:mm:ss[Z]";
    const tz = (_b = params === null || params === void 0 ? void 0 : params.timezone) !== null && _b !== void 0 ? _b : Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dateUTC = date.toUTCString();
    return dayjs.utc(dateUTC).tz(tz).format(format);
};
export const formatDateInTimezoneMemoized = memoize(formatDateInTimezone);
/** YYYY-MM-DD for dayjs */
export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
/**
 * `true` if the portal is being used as a Chrome extension
 *
 * This affects a few things, such as whether tabs should be opened in new windows,
 * whether to display login/logout features, etc.
 */
export const isChromeExtension = window.location.hostname.includes("mail.google.com") || window !== window.parent;
/**
 * `true` if the hostname does not contain 'dash'
 * This affects conditionally creating links that have different urls for production and local environments.
 * Currently, this is used for the Seller Portal link the the sidebar and the Seller Portal login page.
 */
export const isLocal = !window.location.hostname.includes("dash");
// @ts-expect-error
export const variant = (_a = window._DASH_CRM_VARIANT) !== null && _a !== void 0 ? _a : "standard";
export const liveBaseURL = "https://dash.premierbrokeragesystem.com";
export const getRootElement = () => {
    return AppConfig.rootElement;
};
export const fontColorContrastMemoized = memoize(fontColorContrast);
/**
 * Replace an item in an array
 *
 * @param arr The array
 * @param val The value to insert
 * @param where A function that evalues to `true` where the value should be inserted
 * @param options Defines what happens if `where` doesn't indicate where the value should be inserted
 * @returns
 */
export const replaceItemInArray = (params) => {
    var _a;
    const { arr, getValue, options, val, where } = params;
    if (val === undefined && getValue === undefined) {
        throw new Error("Must provide either `val` or `getValue`");
    }
    const ifNotFound = (_a = options === null || options === void 0 ? void 0 : options.ifNotFound) !== null && _a !== void 0 ? _a : "add-to-end";
    const res = [...arr];
    const indx = arr.findIndex((x) => where(x));
    if (indx !== -1) {
        if (val) {
            res[indx] = val;
        }
        else {
            res[indx] = getValue(res[indx]);
        }
    }
    else {
        if (val === undefined) {
            throw new Error("`val` must be provided when adding items to the array");
        }
        if (ifNotFound === "add-to-end") {
            res.push(val);
        }
        if (ifNotFound === "add-to-beginning") {
            res.unshift(val);
        }
    }
    return res;
};
/**
 * Invalidates all queries starting with a given prefix, excluding the specified
 * queries. Useful for invalidating all but a single GET request, for instance.
 * @param prefix
 */
export const invalidateQueriesStartingWith = (queryClient, prefix, exclude) => {
    const queryCache = queryClient.getQueryCache();
    // Retrieve all query keys from the cache
    const allQueryKeys = queryCache.findAll().map((query) => query.queryKey);
    // Filter out the keys that start with the specified prefix
    const matchingQueryKeys = allQueryKeys.filter((queryKey) => {
        if (exclude === null || exclude === void 0 ? void 0 : exclude.find((x) => areQueryKeysEqual(x, queryKey))) {
            return false;
        }
        if (Array.isArray(queryKey)) {
            return queryKey[0].startsWith(prefix);
        }
        return false;
    });
    // Invalidate the matching queries
    matchingQueryKeys.forEach((queryKey) => {
        console.log("invalidating query", queryKey);
        queryClient.invalidateQueries({ queryKey });
    });
};
export const areQueryKeysEqual = (queryKey1, queryKey2) => {
    // If both query keys are strings, compare them directly
    if (typeof queryKey1 === "string" && typeof queryKey2 === "string") {
        return queryKey1 === queryKey2;
    }
    // If both query keys are arrays, compare their elements
    if (Array.isArray(queryKey1) && Array.isArray(queryKey2)) {
        // Convert arrays to strings for comparison
        return JSON.stringify(queryKey1) === JSON.stringify(queryKey2);
    }
    // Otherwise, the query keys are not equal
    return false;
};
/**
 * Removes all undefined values from an object, recursively
 */
export const removeUndefinedRecursive = (obj) => {
    // @ts-expect-error
    const cleanedObj = {};
    // eslint-disable-next-line guard-for-in
    for (const key in obj) {
        cleanedObj[key] = cloneDeep(obj[key]);
        if (Array.isArray(obj[key])) {
            for (const [i, item] of Object.entries(obj[key])) {
                if (!Array.isArray(item) && !isObject(item)) {
                    cleanedObj[key][parseFloat(i)] = item;
                }
                else {
                    const itemCleaned = removeUndefinedRecursive(item);
                    cleanedObj[key][parseFloat(i)] = itemCleaned;
                }
            }
        }
        else if (obj[key] !== undefined) {
            cleanedObj[key] =
                // @ts-expect-error
                obj[key] instanceof Object && !Array.isArray(obj[key]) ? removeUndefinedRecursive(obj[key]) : obj[key];
        }
    }
    return cleanedObj;
};
export const getLinkForEntity = (entityType, uid) => {
    if (entityType === "Buyer Lead") {
        return `/buyers/${uid}`;
    }
    if (entityType === "Seller Lead") {
        return `/sellers/${uid}`;
    }
    if (entityType === "Buyer Contract") {
        return `/buyers/${uid}`;
    }
    if (entityType === "Listing") {
        return `/sellers/${uid}`;
    }
    if (entityType === "User") {
        return `/users/${uid}`;
    }
    if (entityType === "Recruit") {
        return `/recruits/${uid}`;
    }
    throw new Error(`Unsupported entity type ${entityType}`);
};
/**
 * Credit: https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
 * @param email
 * @returns
 */
export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};
export const isValidUrl = (urlString) => {
    try {
        new URL(urlString);
        return true;
    }
    catch (_) {
        return false;
    }
};
export const getCallUrl = (phoneNumber) => {
    var _a, _b;
    let phoneNumberFormatted = null;
    try {
        phoneNumberFormatted = (_b = (_a = parsePhoneNumber(phoneNumber, "US")) === null || _a === void 0 ? void 0 : _a.formatInternational().replace(/\s/g, "")) !== null && _b !== void 0 ? _b : null;
    }
    catch (err) { }
    return `tel:${phoneNumberFormatted}`;
};
export const getSmsUrl = (phoneNumbers) => {
    const phoneNumbersFormatted = phoneNumbers
        .map((x) => {
        var _a, _b;
        let phoneNumberFormatted = null;
        try {
            phoneNumberFormatted = x ? ((_b = (_a = parsePhoneNumber(x, "US")) === null || _a === void 0 ? void 0 : _a.formatInternational().replace(/\s/g, "")) !== null && _b !== void 0 ? _b : null) : null;
        }
        catch (err) { }
        return phoneNumberFormatted;
    })
        .filter((x) => x);
    return `sms://open?addresses=${phoneNumbersFormatted.join(",")}`;
};
export const sortUsersByName = (a, b) => {
    var _a, _b;
    const aName = (_a = a.name) !== null && _a !== void 0 ? _a : "";
    const bName = (_b = b.name) !== null && _b !== void 0 ? _b : "";
    if (aName < bName)
        return -1;
    if (aName > bName)
        return 1;
    return 0;
};
export const doSetsIntersect = (set1, set2) => {
    for (const item of set1) {
        if (set2.has(item)) {
            return true;
        }
    }
    return false;
};
