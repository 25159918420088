/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Create a seller contracts
 */
export const addSellerContract = (sellerContractCreateRequest, options) => {
    return axios.post(`/api/seller_contracts`, sellerContractCreateRequest, options);
};
export const getAddSellerContractMutationOptions = (options) => {
    const mutationKey = ['addSellerContract'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addSellerContract(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a seller contracts
*/
export const useAddSellerContract = (options) => {
    const mutationOptions = getAddSellerContractMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Get a seller contract
*/
export const getSellerContract = (uid, options) => {
    return axios.get(`/api/seller_contracts/${uid}`, options);
};
export const getGetSellerContractQueryKey = (uid) => {
    return [`/api/seller_contracts/${uid}`];
};
export const getGetSellerContractQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetSellerContractQueryKey(uid);
    const queryFn = ({ signal }) => getSellerContract(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a seller contract
 */
export function useGetSellerContract(uid, options) {
    const queryOptions = getGetSellerContractQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Update a seller contract
 */
export const updateSellerContract = (uid, sellerContractUpdateRequest, options) => {
    return axios.post(`/api/seller_contracts/${uid}`, sellerContractUpdateRequest, options);
};
export const getUpdateSellerContractMutationOptions = (options) => {
    const mutationKey = ['updateSellerContract'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateSellerContract(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a seller contract
*/
export const useUpdateSellerContract = (options) => {
    const mutationOptions = getUpdateSellerContractMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Delete a seller_contract
*/
export const deleteSellerContract = (uid, options) => {
    return axios.delete(`/api/seller_contracts/${uid}`, options);
};
export const getDeleteSellerContractMutationOptions = (options) => {
    const mutationKey = ['deleteSellerContract'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteSellerContract(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a seller_contract
*/
export const useDeleteSellerContract = (options) => {
    const mutationOptions = getDeleteSellerContractMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Delete multiple seller contracts
*/
export const bulkDeleteSellerContracts = (sellerContractBulkDeleteRequest, options) => {
    return axios.post(`/api/seller_contracts/bulk_delete`, sellerContractBulkDeleteRequest, options);
};
export const getBulkDeleteSellerContractsMutationOptions = (options) => {
    const mutationKey = ['bulkDeleteSellerContracts'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkDeleteSellerContracts(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete multiple seller contracts
*/
export const useBulkDeleteSellerContracts = (options) => {
    const mutationOptions = getBulkDeleteSellerContractsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Update multiple seller contracts
*/
export const bulkUpdateSellerContracts = (sellerContractBulkUpdateRequest, options) => {
    return axios.post(`/api/seller_contracts/bulk_update`, sellerContractBulkUpdateRequest, options);
};
export const getBulkUpdateSellerContractsMutationOptions = (options) => {
    const mutationKey = ['bulkUpdateSellerContracts'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkUpdateSellerContracts(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update multiple seller contracts
*/
export const useBulkUpdateSellerContracts = (options) => {
    const mutationOptions = getBulkUpdateSellerContractsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Count seller xcontracts
*/
export const countSellerContracts = (sellerContractQuerySerializerWithCertainFieldsRemoved585ba9073b1042acbeeeddfb2e2edacdRequest, options) => {
    return axios.post(`/api/seller_contracts/count`, sellerContractQuerySerializerWithCertainFieldsRemoved585ba9073b1042acbeeeddfb2e2edacdRequest, options);
};
export const getCountSellerContractsMutationOptions = (options) => {
    const mutationKey = ['countSellerContracts'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countSellerContracts(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count seller xcontracts
*/
export const useCountSellerContracts = (options) => {
    const mutationOptions = getCountSellerContractsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Count by stage
*/
export const countSellerContractsByStage = (sellerContractQuerySerializerWithCertainFieldsRemoved585ba9073b1042acbeeeddfb2e2edacdRequest, options) => {
    return axios.post(`/api/seller_contracts/count_by_stage`, sellerContractQuerySerializerWithCertainFieldsRemoved585ba9073b1042acbeeeddfb2e2edacdRequest, options);
};
export const getCountSellerContractsByStageMutationOptions = (options) => {
    const mutationKey = ['countSellerContractsByStage'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countSellerContractsByStage(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count by stage
*/
export const useCountSellerContractsByStage = (options) => {
    const mutationOptions = getCountSellerContractsByStageMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Download seller contracts
*/
export const downloadSellerContracts = (options) => {
    return axios.post(`/api/seller_contracts/download`, undefined, options);
};
export const getDownloadSellerContractsMutationOptions = (options) => {
    const mutationKey = ['downloadSellerContracts'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = () => {
        return downloadSellerContracts(axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Download seller contracts
*/
export const useDownloadSellerContracts = (options) => {
    const mutationOptions = getDownloadSellerContractsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Search seller contracts
*/
export const searchSellerContracts = (paginatedSellerContractQueryRequest, options) => {
    return axios.post(`/api/seller_contracts/search`, paginatedSellerContractQueryRequest, options);
};
export const getSearchSellerContractsMutationOptions = (options) => {
    const mutationKey = ['searchSellerContracts'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return searchSellerContracts(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Search seller contracts
*/
export const useSearchSellerContracts = (options) => {
    const mutationOptions = getSearchSellerContractsMutationOptions(options);
    return useMutation(mutationOptions);
};
