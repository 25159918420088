import * as React from "react";
import { Close, Delete, Save } from "@mui/icons-material";
import { Box, Button, Divider, Stack, Grid2, TextField, InputAdornment, MenuItem, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import omit from "lodash/omit";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router";
import CoreError from "@app/common/CoreError";
import RouterLink from "@app/common/RouterLink";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useLoading from "@app/hooks/useLoading";
import { useListEntityTypes } from "@app/orval/api/entity-types";
import { useListFields } from "@app/orval/api/field-definitions";
import { useAddFieldSection, useDeleteFieldSection, useGetFieldSection, useListFieldSections, useUpdateFieldSection, } from "@app/orval/api/field-sections";
import { useListStages } from "@app/orval/api/stages";
import { INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { CONFIRMATION_DIALOG_ERROR_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import { replaceItemInArray } from "@app/util/Utils";
import { getAutocompleteSuggestionsForField, isChildSection } from "./utils";
import FormulaField from "../fields/components/FormulaField";
const DEFAULT_SUBMISSION = {
    entity_type: "Contact",
    parent_section: null,
    name: "New Section",
    validation: "return true;",
};
const SectionsForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const { uid } = props;
    const queryClient = useQueryClient();
    const confirm = useConfirm();
    const errorDialog = useErrorDialog();
    const navigate = useNavigate();
    const [formData, setFormData] = React.useState(uid ? null : DEFAULT_SUBMISSION);
    const getSectionApi = useGetFieldSection(uid !== null && uid !== void 0 ? uid : "", { query: { enabled: false } });
    const listSectionsApi = useListFieldSections({}, REFETCH_CACHE_PARAMS);
    const sections = (_b = (_a = listSectionsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listEntityTypesApi = useListEntityTypes(INFINITE_CACHE_PARAMS);
    const entityTypes = (_d = (_c = listEntityTypesApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const listStagesApi = useListStages({}, REFETCH_CACHE_PARAMS);
    const stages = (_f = (_e = listStagesApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const listFieldsApi = useListFields({}, REFETCH_CACHE_PARAMS);
    const fields = (_h = (_g = listFieldsApi.data) === null || _g === void 0 ? void 0 : _g.data) !== null && _h !== void 0 ? _h : null;
    const autocompleteSuggestions = React.useMemo(() => {
        return fields && (formData === null || formData === void 0 ? void 0 : formData.entity_type)
            ? getAutocompleteSuggestionsForField(formData.entity_type, fields.filter((x) => x.entity_type === formData.entity_type), stages !== null && stages !== void 0 ? stages : undefined)
            : null;
    }, [fields, formData === null || formData === void 0 ? void 0 : formData.entity_type, stages]);
    const autocompleteSuggestionsWithUser = React.useMemo(() => {
        return autocompleteSuggestions
            ? [
                {
                    label: "$USER",
                    type: "Map",
                    documentation: "The user doing the update. Use `$USER.roles` to validate against the array of user roles.",
                },
                ...autocompleteSuggestions,
            ]
            : null;
    }, [autocompleteSuggestions]);
    const createSectionApi = useAddFieldSection();
    const updateSectionApi = useUpdateFieldSection();
    const deleteSectionApi = useDeleteFieldSection();
    const loading = useLoading({
        items: [
            {
                label: "Loading section info...",
                queryResult: getSectionApi,
                isRelevant: !!uid,
            },
            {
                label: "Loading entity types...",
                queryResult: listEntityTypesApi,
            },
            {
                label: "Creating new section...",
                mutationResult: createSectionApi,
            },
            {
                label: "Updating section...",
                mutationResult: updateSectionApi,
            },
            {
                label: "Deleting section...",
                mutationResult: deleteSectionApi,
            },
        ],
    });
    React.useEffect(() => {
        if (uid) {
            setFormData(null);
            getSectionApi
                .refetch()
                .then((result) => {
                var _a, _b;
                setFormData((_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null);
            })
                .catch(errorDialog);
        }
        else {
            setFormData(DEFAULT_SUBMISSION);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid]);
    const isValid = React.useMemo(() => {
        return !!((formData === null || formData === void 0 ? void 0 : formData.entity_type) && formData.name.trim());
    }, [formData]);
    const setEntityType = React.useCallback((e) => {
        const val = e.target.value;
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { entity_type: val })));
    }, [setFormData]);
    const setParentSection = React.useCallback((e) => {
        const val = e.target.value;
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { parent_section: val })));
    }, [setFormData]);
    const setName = React.useCallback((e) => {
        const val = e.target.value;
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { name: val })));
    }, [setFormData]);
    const setValidation = React.useCallback((formula) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { validation: formula })));
    }, [setFormData]);
    const saveSection = React.useCallback(() => {
        const dataSanitized = {
            entity_type: formData.entity_type,
            name: formData.name,
            validation: formData.validation,
            parent_section: formData.parent_section,
        };
        if (uid) {
            updateSectionApi
                .mutateAsync({ uid, data: omit(dataSanitized, "entity_type") })
                .then((result) => {
                if (listSectionsApi.data) {
                    queryClient.setQueryData(listSectionsApi.queryKey, (old) => {
                        if (!old)
                            return old;
                        return Object.assign(Object.assign({}, old), { data: replaceItemInArray({ arr: old.data, val: result.data, where: (item) => item.uid === uid }) });
                    });
                }
                navigate("/field-management");
            })
                .catch(errorDialog);
        }
        else {
            createSectionApi
                .mutateAsync({ data: dataSanitized })
                .then((result) => {
                if (listSectionsApi.data) {
                    queryClient.setQueryData(listSectionsApi.queryKey, (old) => {
                        if (!old)
                            return old;
                        return Object.assign(Object.assign({}, old), { data: [...old.data, result.data] });
                    });
                }
                navigate("/field-management");
            })
                .catch(errorDialog);
        }
    }, [
        createSectionApi,
        errorDialog,
        formData,
        listSectionsApi.data,
        listSectionsApi.queryKey,
        navigate,
        queryClient,
        uid,
        updateSectionApi,
    ]);
    const deleteSection = React.useCallback(() => {
        confirm(Object.assign(Object.assign({}, CONFIRMATION_DIALOG_ERROR_DEFAULT_OPTIONS), { description: "You won't be able to undo this action. (This won't delete any fields within the section)" })).then((result) => {
            if (result.confirmed) {
                deleteSectionApi
                    .mutateAsync({ uid: uid })
                    .then(() => {
                    if (listSectionsApi.data) {
                        queryClient.setQueryData(listSectionsApi.queryKey, (old) => {
                            if (!old)
                                return old;
                            return Object.assign(Object.assign({}, old), { data: old.data.filter((x) => x.uid !== uid) });
                        });
                    }
                    navigate("/field-management");
                })
                    .catch(errorDialog);
            }
        });
    }, [
        confirm,
        deleteSectionApi,
        errorDialog,
        listSectionsApi.data,
        listSectionsApi.queryKey,
        navigate,
        queryClient,
        uid,
    ]);
    if (loading.isLoading && loading.loadingComponent) {
        return loading.loadingComponent;
    }
    if (!formData) {
        return React.createElement(CoreError, { error: "An error occurred loading the component" });
    }
    return (React.createElement(Stack, { spacing: 2 },
        React.createElement(Box, { pl: 2, pr: 2 },
            React.createElement(Typography, { variant: "h6" }, uid ? (_j = getSectionApi.data) === null || _j === void 0 ? void 0 : _j.data.name : "New Section")),
        React.createElement(Divider, null),
        React.createElement(Box, { p: 2 },
            React.createElement(Grid2, { container: true, direction: "column", spacing: 4, wrap: "nowrap" },
                !uid && (React.createElement(Grid2, null,
                    React.createElement(TextField, { fullWidth: true, size: "small", select: true, slotProps: {
                            input: {
                                startAdornment: React.createElement(InputAdornment, { position: "start" }, "Entity Type"),
                            },
                        }, value: formData.entity_type, onChange: setEntityType }, (entityTypes !== null && entityTypes !== void 0 ? entityTypes : []).map((item) => (React.createElement(MenuItem, { key: item.name, value: item.name }, item.name)))))),
                React.createElement(Grid2, null,
                    React.createElement(TextField, { fullWidth: true, size: "small", select: true, slotProps: {
                            input: {
                                startAdornment: React.createElement(InputAdornment, { position: "start" }, "Parent Section"),
                            },
                        }, value: (_k = formData.parent_section) !== null && _k !== void 0 ? _k : "", onChange: setParentSection }, (sections !== null && sections !== void 0 ? sections : [])
                        .filter((item) => item.entity_type === formData.entity_type &&
                        item.uid !== uid &&
                        !(uid && isChildSection(item.uid, uid, sections !== null && sections !== void 0 ? sections : [])))
                        .map((item) => (React.createElement(MenuItem, { key: item.uid, value: item.uid }, item.name))))),
                React.createElement(Grid2, null,
                    React.createElement(TextField, { fullWidth: true, size: "small", variant: "outlined", slotProps: {
                            input: {
                                startAdornment: React.createElement(InputAdornment, { position: "start" }, "Name"),
                            },
                        }, value: formData.name, onChange: setName })),
                React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, "Validation Formula"),
                    React.createElement(FormulaField, { formula: (_l = formData.validation) !== null && _l !== void 0 ? _l : "", onValueChange: setValidation, autocompleteSuggestions: autocompleteSuggestionsWithUser !== null && autocompleteSuggestionsWithUser !== void 0 ? autocompleteSuggestionsWithUser : [], helpText: "If this formula evaluates to `false` (or anything false-y other than `undefined`), the section will be hidden from certain UI. Assumed to be `true` if no formula is provided or if the return value is `undefined`. Use `$<field_name>` to refer to other field values, or `$<$USER>` to refer to the current user." })))),
        React.createElement(Divider, null),
        React.createElement(Box, { p: 2 },
            React.createElement(Grid2, { container: true, spacing: 1 },
                uid && (React.createElement(Grid2, null,
                    React.createElement(Button, { onClick: deleteSection, variant: "text", color: "error", startIcon: React.createElement(Delete, null), tabIndex: -1 }, "Delete"))),
                React.createElement(Grid2, { style: { flex: 1 } }),
                React.createElement(Grid2, null,
                    React.createElement(Button, { component: RouterLink, to: "/field-management", variant: "text", startIcon: React.createElement(Close, null), tabIndex: -1 }, "Cancel")),
                React.createElement(Grid2, null,
                    React.createElement(Button, { onClick: saveSection, variant: "contained", tabIndex: -1, startIcon: React.createElement(Save, null), disabled: !isValid }, "Save"))))));
};
export default SectionsForm;
