// eslint-disable-next-line import/prefer-default-export
export const canEditBuyerLead = (params) => {
    var _a, _b;
    const { allUsers, buyerLead, sessionUser } = params;
    if (!sessionUser) {
        return false;
    }
    if (sessionUser.hasRole(["Superadmin", "Admin"])) {
        return true;
    }
    // producers can edit their own boxes
    if (buyerLead.agents.some((x) => {
        return x.type === "Producer" && x.agent.uid === sessionUser.uid;
    })) {
        return true;
    }
    // collaborators can edit boxes if they've been given edit access
    if (buyerLead.collaborators.some((x) => {
        return x.user.uid === sessionUser.uid && x.can_edit;
    })) {
        return true;
    }
    // Managing Partners can edit boxes if they are the managing partner
    // of the producer'soffice
    const user = allUsers === null || allUsers === void 0 ? void 0 : allUsers[sessionUser.uid];
    if (!user) {
        return false;
    }
    if (buyerLead.agents.some((x) => { var _a, _b; return x.type === "Producer" && allUsers[x.agent.uid] && ((_a = allUsers[x.agent.uid].office) === null || _a === void 0 ? void 0 : _a.name) === ((_b = user.office) === null || _b === void 0 ? void 0 : _b.name); })) {
        return true;
    }
    // Operating Partners and Success Partners can edit boxes if they are
    // on the producer's team
    if ((((_a = user.team) === null || _a === void 0 ? void 0 : _a.role) === "Operating Partner" || ((_b = user.team) === null || _b === void 0 ? void 0 : _b.role) === "Success Partner") &&
        buyerLead.agents.some((x) => { var _a, _b; return x.type === "Producer" && allUsers[x.agent.uid] && ((_a = allUsers[x.agent.uid].team) === null || _a === void 0 ? void 0 : _a.uid) === ((_b = user.team) === null || _b === void 0 ? void 0 : _b.uid); })) {
        return true;
    }
    return false;
};
