import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import BuyerLeadsForm from "./BuyerLeadsForm";
const BuyerLeadsFormPage = () => {
    var _a;
    useDocumentTitle("Buyers");
    const params = useParams();
    const searchParams = useSearchParams();
    const tab = (_a = searchParams[0].get("tab")) !== null && _a !== void 0 ? _a : undefined;
    return React.createElement(BuyerLeadsForm, { tab: tab, uid: params.uid });
};
export default BuyerLeadsFormPage;
