/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Create a seller leads
 */
export const addSellerLead = (sellerLeadCreateRequest, options) => {
    return axios.post(`/api/seller_leads`, sellerLeadCreateRequest, options);
};
export const getAddSellerLeadMutationOptions = (options) => {
    const mutationKey = ['addSellerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addSellerLead(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a seller leads
*/
export const useAddSellerLead = (options) => {
    const mutationOptions = getAddSellerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Add an agent
*/
export const addSellerLeadAgent = (sellerLeadUid, sellerLeadUpdateAgentRequest, options) => {
    return axios.post(`/api/seller_leads/${sellerLeadUid}/add_agent`, sellerLeadUpdateAgentRequest, options);
};
export const getAddSellerLeadAgentMutationOptions = (options) => {
    const mutationKey = ['addSellerLeadAgent'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { sellerLeadUid, data } = props !== null && props !== void 0 ? props : {};
        return addSellerLeadAgent(sellerLeadUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add an agent
*/
export const useAddSellerLeadAgent = (options) => {
    const mutationOptions = getAddSellerLeadAgentMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Add a client
*/
export const addSellerLeadClient = (sellerLeadUid, sellerLeadAddClientRequest, options) => {
    return axios.post(`/api/seller_leads/${sellerLeadUid}/add_client`, sellerLeadAddClientRequest, options);
};
export const getAddSellerLeadClientMutationOptions = (options) => {
    const mutationKey = ['addSellerLeadClient'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { sellerLeadUid, data } = props !== null && props !== void 0 ? props : {};
        return addSellerLeadClient(sellerLeadUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add a client
*/
export const useAddSellerLeadClient = (options) => {
    const mutationOptions = getAddSellerLeadClientMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Add a collaborator
*/
export const addCollaboratorForSellerLead = (sellerLeadUid, sellerLeadCollaboratorWriteRequest, options) => {
    return axios.post(`/api/seller_leads/${sellerLeadUid}/add_collaborator`, sellerLeadCollaboratorWriteRequest, options);
};
export const getAddCollaboratorForSellerLeadMutationOptions = (options) => {
    const mutationKey = ['addCollaboratorForSellerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { sellerLeadUid, data } = props !== null && props !== void 0 ? props : {};
        return addCollaboratorForSellerLead(sellerLeadUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add a collaborator
*/
export const useAddCollaboratorForSellerLead = (options) => {
    const mutationOptions = getAddCollaboratorForSellerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Add a PSP
*/
export const addSellerLeadPsp = (sellerLeadUid, pspUid, options) => {
    return axios.post(`/api/seller_leads/${sellerLeadUid}/add_psp/${pspUid}`, undefined, options);
};
export const getAddSellerLeadPspMutationOptions = (options) => {
    const mutationKey = ['addSellerLeadPsp'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { sellerLeadUid, pspUid } = props !== null && props !== void 0 ? props : {};
        return addSellerLeadPsp(sellerLeadUid, pspUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add a PSP
*/
export const useAddSellerLeadPsp = (options) => {
    const mutationOptions = getAddSellerLeadPspMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Add a showing
*/
export const addSellerLeadShowing = (sellerLeadUid, sellerLeadUpdateShowingRequest, options) => {
    return axios.post(`/api/seller_leads/${sellerLeadUid}/add_showing`, sellerLeadUpdateShowingRequest, options);
};
export const getAddSellerLeadShowingMutationOptions = (options) => {
    const mutationKey = ['addSellerLeadShowing'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { sellerLeadUid, data } = props !== null && props !== void 0 ? props : {};
        return addSellerLeadShowing(sellerLeadUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add a showing
*/
export const useAddSellerLeadShowing = (options) => {
    const mutationOptions = getAddSellerLeadShowingMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Add a vendor
*/
export const addSellerLeadVendor = (sellerLeadUid, sellerLeadUpdateVendorRequest, options) => {
    return axios.post(`/api/seller_leads/${sellerLeadUid}/add_vendor`, sellerLeadUpdateVendorRequest, options);
};
export const getAddSellerLeadVendorMutationOptions = (options) => {
    const mutationKey = ['addSellerLeadVendor'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { sellerLeadUid, data } = props !== null && props !== void 0 ? props : {};
        return addSellerLeadVendor(sellerLeadUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add a vendor
*/
export const useAddSellerLeadVendor = (options) => {
    const mutationOptions = getAddSellerLeadVendorMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Gets all Twilio calls involving the lead

_Authorization: only logged in, registered users can use this endpoint._
* @summary List calls for seller lead
*/
export const listCallsForSellerLead = (sellerLeadUid, options) => {
    return axios.get(`/api/seller_leads/${sellerLeadUid}/calls`, options);
};
export const getListCallsForSellerLeadQueryKey = (sellerLeadUid) => {
    return [`/api/seller_leads/${sellerLeadUid}/calls`];
};
export const getListCallsForSellerLeadQueryOptions = (sellerLeadUid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListCallsForSellerLeadQueryKey(sellerLeadUid);
    const queryFn = ({ signal }) => listCallsForSellerLead(sellerLeadUid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(sellerLeadUid) }, queryOptions);
};
/**
 * @summary List calls for seller lead
 */
export function useListCallsForSellerLead(sellerLeadUid, options) {
    const queryOptions = getListCallsForSellerLeadQueryOptions(sellerLeadUid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Gets all Gmail messages related to the lead

_Authorization: only logged in, registered users can use this endpoint._
 * @summary List emails for lead
 */
export const listEmailsForSellerLead = (sellerLeadUid, options) => {
    return axios.get(`/api/seller_leads/${sellerLeadUid}/emails`, options);
};
export const getListEmailsForSellerLeadQueryKey = (sellerLeadUid) => {
    return [`/api/seller_leads/${sellerLeadUid}/emails`];
};
export const getListEmailsForSellerLeadQueryOptions = (sellerLeadUid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListEmailsForSellerLeadQueryKey(sellerLeadUid);
    const queryFn = ({ signal }) => listEmailsForSellerLead(sellerLeadUid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(sellerLeadUid) }, queryOptions);
};
/**
 * @summary List emails for lead
 */
export function useListEmailsForSellerLead(sellerLeadUid, options) {
    const queryOptions = getListEmailsForSellerLeadQueryOptions(sellerLeadUid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Removes a client from the lead. Does not delete the client.

_Authorization: only logged in, registered users can use this endpoint._
 * @summary Remove a client
 */
export const removeSellerLeadClient = (sellerLeadUid, sellerLeadRemoveClientRequest, options) => {
    return axios.post(`/api/seller_leads/${sellerLeadUid}/remove_client`, sellerLeadRemoveClientRequest, options);
};
export const getRemoveSellerLeadClientMutationOptions = (options) => {
    const mutationKey = ['removeSellerLeadClient'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { sellerLeadUid, data } = props !== null && props !== void 0 ? props : {};
        return removeSellerLeadClient(sellerLeadUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Remove a client
*/
export const useRemoveSellerLeadClient = (options) => {
    const mutationOptions = getRemoveSellerLeadClientMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Remove a collaborator
*/
export const removeCollaboratorForSellerLead = (sellerLeadUid, uid, options) => {
    return axios.delete(`/api/seller_leads/${sellerLeadUid}/remove_collaborator/${uid}`, options);
};
export const getRemoveCollaboratorForSellerLeadMutationOptions = (options) => {
    const mutationKey = ['removeCollaboratorForSellerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { sellerLeadUid, uid } = props !== null && props !== void 0 ? props : {};
        return removeCollaboratorForSellerLead(sellerLeadUid, uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Remove a collaborator
*/
export const useRemoveCollaboratorForSellerLead = (options) => {
    const mutationOptions = getRemoveCollaboratorForSellerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Remove a PSP
*/
export const removeSellerLeadPsp = (sellerLeadUid, pspUid, options) => {
    return axios.delete(`/api/seller_leads/${sellerLeadUid}/remove_psp/${pspUid}`, options);
};
export const getRemoveSellerLeadPspMutationOptions = (options) => {
    const mutationKey = ['removeSellerLeadPsp'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { sellerLeadUid, pspUid } = props !== null && props !== void 0 ? props : {};
        return removeSellerLeadPsp(sellerLeadUid, pspUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Remove a PSP
*/
export const useRemoveSellerLeadPsp = (options) => {
    const mutationOptions = getRemoveSellerLeadPspMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Reorder clients
*/
export const reorderSellerLeadClients = (sellerLeadUid, sellerLeadReorderClientRequest, options) => {
    return axios.post(`/api/seller_leads/${sellerLeadUid}/reorder_clients`, sellerLeadReorderClientRequest, options);
};
export const getReorderSellerLeadClientsMutationOptions = (options) => {
    const mutationKey = ['reorderSellerLeadClients'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { sellerLeadUid, data } = props !== null && props !== void 0 ? props : {};
        return reorderSellerLeadClients(sellerLeadUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Reorder clients
*/
export const useReorderSellerLeadClients = (options) => {
    const mutationOptions = getReorderSellerLeadClientsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Gets all Streak comments related to the lead

_Authorization: only logged in, registered users can use this endpoint._
* @summary List Streak comments for lead
*/
export const listStreakCommentsForSellerLead = (sellerLeadUid, options) => {
    return axios.get(`/api/seller_leads/${sellerLeadUid}/streak_comments`, options);
};
export const getListStreakCommentsForSellerLeadQueryKey = (sellerLeadUid) => {
    return [`/api/seller_leads/${sellerLeadUid}/streak_comments`];
};
export const getListStreakCommentsForSellerLeadQueryOptions = (sellerLeadUid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListStreakCommentsForSellerLeadQueryKey(sellerLeadUid);
    const queryFn = ({ signal }) => listStreakCommentsForSellerLead(sellerLeadUid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(sellerLeadUid) }, queryOptions);
};
/**
 * @summary List Streak comments for lead
 */
export function useListStreakCommentsForSellerLead(sellerLeadUid, options) {
    const queryOptions = getListStreakCommentsForSellerLeadQueryOptions(sellerLeadUid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Gets all Streak files related to the lead

_Authorization: only logged in, registered users can use this endpoint._
 * @summary List Streak files for lead
 */
export const listStreakFilesForSellerLead = (sellerLeadUid, options) => {
    return axios.get(`/api/seller_leads/${sellerLeadUid}/streak_files`, options);
};
export const getListStreakFilesForSellerLeadQueryKey = (sellerLeadUid) => {
    return [`/api/seller_leads/${sellerLeadUid}/streak_files`];
};
export const getListStreakFilesForSellerLeadQueryOptions = (sellerLeadUid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListStreakFilesForSellerLeadQueryKey(sellerLeadUid);
    const queryFn = ({ signal }) => listStreakFilesForSellerLead(sellerLeadUid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(sellerLeadUid) }, queryOptions);
};
/**
 * @summary List Streak files for lead
 */
export function useListStreakFilesForSellerLead(sellerLeadUid, options) {
    const queryOptions = getListStreakFilesForSellerLeadQueryOptions(sellerLeadUid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Update a collaborator
 */
export const updateCollaboratorForSellerLead = (sellerLeadUid, uid, sellerLeadCollaboratorWriteRequest, options) => {
    return axios.post(`/api/seller_leads/${sellerLeadUid}/update_collaborator/${uid}`, sellerLeadCollaboratorWriteRequest, options);
};
export const getUpdateCollaboratorForSellerLeadMutationOptions = (options) => {
    const mutationKey = ['updateCollaboratorForSellerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { sellerLeadUid, uid, data } = props !== null && props !== void 0 ? props : {};
        return updateCollaboratorForSellerLead(sellerLeadUid, uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a collaborator
*/
export const useUpdateCollaboratorForSellerLead = (options) => {
    const mutationOptions = getUpdateCollaboratorForSellerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Get a seller lead
*/
export const getSellerLead = (uid, options) => {
    return axios.get(`/api/seller_leads/${uid}`, options);
};
export const getGetSellerLeadQueryKey = (uid) => {
    return [`/api/seller_leads/${uid}`];
};
export const getGetSellerLeadQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetSellerLeadQueryKey(uid);
    const queryFn = ({ signal }) => getSellerLead(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a seller lead
 */
export function useGetSellerLead(uid, options) {
    const queryOptions = getGetSellerLeadQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Update a seller lead
 */
export const updateSellerLead = (uid, sellerLeadUpdateRequest, options) => {
    return axios.post(`/api/seller_leads/${uid}`, sellerLeadUpdateRequest, options);
};
export const getUpdateSellerLeadMutationOptions = (options) => {
    const mutationKey = ['updateSellerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateSellerLead(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a seller lead
*/
export const useUpdateSellerLead = (options) => {
    const mutationOptions = getUpdateSellerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Delete a seller lead
*/
export const deleteSellerLead = (uid, options) => {
    return axios.delete(`/api/seller_leads/${uid}`, options);
};
export const getDeleteSellerLeadMutationOptions = (options) => {
    const mutationKey = ['deleteSellerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteSellerLead(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a seller lead
*/
export const useDeleteSellerLead = (options) => {
    const mutationOptions = getDeleteSellerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Duplicate a seller lead
*/
export const duplicateSellerLead = (uid, options) => {
    return axios.post(`/api/seller_leads/${uid}/duplicate`, undefined, options);
};
export const getDuplicateSellerLeadMutationOptions = (options) => {
    const mutationKey = ['duplicateSellerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return duplicateSellerLead(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Duplicate a seller lead
*/
export const useDuplicateSellerLead = (options) => {
    const mutationOptions = getDuplicateSellerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Duplicate a seller lead as a buyer lead
*/
export const duplicateSellerLeadAsBuyerLead = (uid, options) => {
    return axios.post(`/api/seller_leads/${uid}/duplicate_as_buyer_lead`, undefined, options);
};
export const getDuplicateSellerLeadAsBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['duplicateSellerLeadAsBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return duplicateSellerLeadAsBuyerLead(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Duplicate a seller lead as a buyer lead
*/
export const useDuplicateSellerLeadAsBuyerLead = (options) => {
    const mutationOptions = getDuplicateSellerLeadAsBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Create multiple seller leads
*/
export const bulkCreateSellerLeads = (sellerLeadBulkCreateRequest, options) => {
    return axios.post(`/api/seller_leads/bulk_create`, sellerLeadBulkCreateRequest, options);
};
export const getBulkCreateSellerLeadsMutationOptions = (options) => {
    const mutationKey = ['bulkCreateSellerLeads'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkCreateSellerLeads(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create multiple seller leads
*/
export const useBulkCreateSellerLeads = (options) => {
    const mutationOptions = getBulkCreateSellerLeadsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Delete multiple seller leads
*/
export const bulkDeleteSellerLeads = (sellerLeadBulkDeleteRequest, options) => {
    return axios.post(`/api/seller_leads/bulk_delete`, sellerLeadBulkDeleteRequest, options);
};
export const getBulkDeleteSellerLeadsMutationOptions = (options) => {
    const mutationKey = ['bulkDeleteSellerLeads'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkDeleteSellerLeads(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete multiple seller leads
*/
export const useBulkDeleteSellerLeads = (options) => {
    const mutationOptions = getBulkDeleteSellerLeadsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Update multiple seller leads
*/
export const bulkUpdateSellerLeads = (sellerLeadBulkUpdateRequest, options) => {
    return axios.post(`/api/seller_leads/bulk_update`, sellerLeadBulkUpdateRequest, options);
};
export const getBulkUpdateSellerLeadsMutationOptions = (options) => {
    const mutationKey = ['bulkUpdateSellerLeads'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkUpdateSellerLeads(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update multiple seller leads
*/
export const useBulkUpdateSellerLeads = (options) => {
    const mutationOptions = getBulkUpdateSellerLeadsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Count seller leads
*/
export const countSellerLeads = (sellerLeadQuerySerializerWithCertainFieldsRemoved54004e20755f4bdcb2a8c868976d8fadRequest, options) => {
    return axios.post(`/api/seller_leads/count`, sellerLeadQuerySerializerWithCertainFieldsRemoved54004e20755f4bdcb2a8c868976d8fadRequest, options);
};
export const getCountSellerLeadsMutationOptions = (options) => {
    const mutationKey = ['countSellerLeads'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countSellerLeads(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count seller leads
*/
export const useCountSellerLeads = (options) => {
    const mutationOptions = getCountSellerLeadsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Count by stage
*/
export const countSellerLeadsByStage = (sellerLeadQuerySerializerWithCertainFieldsRemoved54004e20755f4bdcb2a8c868976d8fadRequest, options) => {
    return axios.post(`/api/seller_leads/count_by_stage`, sellerLeadQuerySerializerWithCertainFieldsRemoved54004e20755f4bdcb2a8c868976d8fadRequest, options);
};
export const getCountSellerLeadsByStageMutationOptions = (options) => {
    const mutationKey = ['countSellerLeadsByStage'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countSellerLeadsByStage(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count by stage
*/
export const useCountSellerLeadsByStage = (options) => {
    const mutationOptions = getCountSellerLeadsByStageMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Download seller leads
*/
export const downloadSellerLeads = (options) => {
    return axios.post(`/api/seller_leads/download`, undefined, options);
};
export const getDownloadSellerLeadsMutationOptions = (options) => {
    const mutationKey = ['downloadSellerLeads'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = () => {
        return downloadSellerLeads(axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Download seller leads
*/
export const useDownloadSellerLeads = (options) => {
    const mutationOptions = getDownloadSellerLeadsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Mark a file upload as "finished"

_Authorization: none required_
* @summary Finish a file upload
*/
export const finishSellerLeadFileUpload = (fileUid, options) => {
    return axios.post(`/api/seller_leads/finish_file_upload/${fileUid}`, undefined, options);
};
export const getFinishSellerLeadFileUploadMutationOptions = (options) => {
    const mutationKey = ['finishSellerLeadFileUpload'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { fileUid } = props !== null && props !== void 0 ? props : {};
        return finishSellerLeadFileUpload(fileUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Finish a file upload
*/
export const useFinishSellerLeadFileUpload = (options) => {
    const mutationOptions = getFinishSellerLeadFileUploadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Import seller leads
*/
export const importSellerLeads = (importSellerLeadRequest, options) => {
    return axios.post(`/api/seller_leads/import`, importSellerLeadRequest, options);
};
export const getImportSellerLeadsMutationOptions = (options) => {
    const mutationKey = ['importSellerLeads'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return importSellerLeads(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Import seller leads
*/
export const useImportSellerLeads = (options) => {
    const mutationOptions = getImportSellerLeadsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary List office exclusive seller leads
*/
export const listOfficeExclusiveSellerLeads = (options) => {
    return axios.get(`/api/seller_leads/office_exclusive`, options);
};
export const getListOfficeExclusiveSellerLeadsQueryKey = () => {
    return [`/api/seller_leads/office_exclusive`];
};
export const getListOfficeExclusiveSellerLeadsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListOfficeExclusiveSellerLeadsQueryKey();
    const queryFn = ({ signal }) => listOfficeExclusiveSellerLeads(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List office exclusive seller leads
 */
export function useListOfficeExclusiveSellerLeads(options) {
    const queryOptions = getListOfficeExclusiveSellerLeadsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Removes an agent from the lead. Does not delete the agent profile.

_Authorization: only logged in, registered users can use this endpoint._
 * @summary Remove an agent
 */
export const removeSellerLeadAgent = (uid, options) => {
    return axios.delete(`/api/seller_leads/remove_agent/${uid}`, options);
};
export const getRemoveSellerLeadAgentMutationOptions = (options) => {
    const mutationKey = ['removeSellerLeadAgent'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return removeSellerLeadAgent(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Remove an agent
*/
export const useRemoveSellerLeadAgent = (options) => {
    const mutationOptions = getRemoveSellerLeadAgentMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Remove a showing
*/
export const removeSellerLeadShowing = (uid, options) => {
    return axios.delete(`/api/seller_leads/remove_showing/${uid}`, options);
};
export const getRemoveSellerLeadShowingMutationOptions = (options) => {
    const mutationKey = ['removeSellerLeadShowing'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return removeSellerLeadShowing(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Remove a showing
*/
export const useRemoveSellerLeadShowing = (options) => {
    const mutationOptions = getRemoveSellerLeadShowingMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Remove a vendor
*/
export const removeSellerLeadVendor = (uid, options) => {
    return axios.delete(`/api/seller_leads/remove_vendor/${uid}`, options);
};
export const getRemoveSellerLeadVendorMutationOptions = (options) => {
    const mutationKey = ['removeSellerLeadVendor'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return removeSellerLeadVendor(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Remove a vendor
*/
export const useRemoveSellerLeadVendor = (options) => {
    const mutationOptions = getRemoveSellerLeadVendorMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Search seller leads
*/
export const searchSellerLeads = (paginatedSellerLeadQueryRequest, options) => {
    return axios.post(`/api/seller_leads/search`, paginatedSellerLeadQueryRequest, options);
};
export const getSearchSellerLeadsMutationOptions = (options) => {
    const mutationKey = ['searchSellerLeads'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return searchSellerLeads(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Search seller leads
*/
export const useSearchSellerLeads = (options) => {
    const mutationOptions = getSearchSellerLeadsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Update an agent
*/
export const updateSellerLeadAgent = (uid, sellerLeadUpdateAgentRequest, options) => {
    return axios.post(`/api/seller_leads/update_agent/${uid}`, sellerLeadUpdateAgentRequest, options);
};
export const getUpdateSellerLeadAgentMutationOptions = (options) => {
    const mutationKey = ['updateSellerLeadAgent'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateSellerLeadAgent(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update an agent
*/
export const useUpdateSellerLeadAgent = (options) => {
    const mutationOptions = getUpdateSellerLeadAgentMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Update a showing
*/
export const updateSellerLeadShowing = (uid, sellerLeadUpdateShowingRequest, options) => {
    return axios.post(`/api/seller_leads/update_showing/${uid}`, sellerLeadUpdateShowingRequest, options);
};
export const getUpdateSellerLeadShowingMutationOptions = (options) => {
    const mutationKey = ['updateSellerLeadShowing'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateSellerLeadShowing(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a showing
*/
export const useUpdateSellerLeadShowing = (options) => {
    const mutationOptions = getUpdateSellerLeadShowingMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Update a vendor
*/
export const updateSellerLeadVendor = (uid, sellerLeadUpdateVendorRequest, options) => {
    return axios.post(`/api/seller_leads/update_vendor/${uid}`, sellerLeadUpdateVendorRequest, options);
};
export const getUpdateSellerLeadVendorMutationOptions = (options) => {
    const mutationKey = ['updateSellerLeadVendor'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateSellerLeadVendor(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a vendor
*/
export const useUpdateSellerLeadVendor = (options) => {
    const mutationOptions = getUpdateSellerLeadVendorMutationOptions(options);
    return useMutation(mutationOptions);
};
