/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary Count closed contracts
 */
export const countClosedContracts = (agentDashboardMetricBodySerializerWithCertainFieldsRemoved3ecd9dd5019f4ad79f4ceabb7ddfb19fRequest, options) => {
    return axios.post(`/api/agent_dashboard/count_closed_contracts`, agentDashboardMetricBodySerializerWithCertainFieldsRemoved3ecd9dd5019f4ad79f4ceabb7ddfb19fRequest, options);
};
export const getCountClosedContractsMutationOptions = (options) => {
    const mutationKey = ['countClosedContracts'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countClosedContracts(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count closed contracts
*/
export const useCountClosedContracts = (options) => {
    const mutationOptions = getCountClosedContractsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Count contracts by month
*/
export const countContractsByMonth = (agentDashboardMetricBodySerializerWithCertainFieldsRemoved3ecd9dd5019f4ad79f4ceabb7ddfb19fRequest, options) => {
    return axios.post(`/api/agent_dashboard/count_contracts_by_month`, agentDashboardMetricBodySerializerWithCertainFieldsRemoved3ecd9dd5019f4ad79f4ceabb7ddfb19fRequest, options);
};
export const getCountContractsByMonthMutationOptions = (options) => {
    const mutationKey = ['countContractsByMonth'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countContractsByMonth(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count contracts by month
*/
export const useCountContractsByMonth = (options) => {
    const mutationOptions = getCountContractsByMonthMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Count contracts by source
*/
export const countContractsBySource = (agentDashboardMetricBodySerializerWithCertainFieldsRemoved3ecd9dd5019f4ad79f4ceabb7ddfb19fRequest, options) => {
    return axios.post(`/api/agent_dashboard/count_contracts_by_source`, agentDashboardMetricBodySerializerWithCertainFieldsRemoved3ecd9dd5019f4ad79f4ceabb7ddfb19fRequest, options);
};
export const getCountContractsBySourceMutationOptions = (options) => {
    const mutationKey = ['countContractsBySource'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countContractsBySource(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count contracts by source
*/
export const useCountContractsBySource = (options) => {
    const mutationOptions = getCountContractsBySourceMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Count pending contracts
*/
export const countPendingContracts = (agentDashboardMetricBodySerializerWithCertainFieldsRemoved3ecd9dd5019f4ad79f4ceabb7ddfb19fRequest, options) => {
    return axios.post(`/api/agent_dashboard/count_pending_contracts`, agentDashboardMetricBodySerializerWithCertainFieldsRemoved3ecd9dd5019f4ad79f4ceabb7ddfb19fRequest, options);
};
export const getCountPendingContractsMutationOptions = (options) => {
    const mutationKey = ['countPendingContracts'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countPendingContracts(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count pending contracts
*/
export const useCountPendingContracts = (options) => {
    const mutationOptions = getCountPendingContractsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Count transactions
*/
export const countTransactions = (agentDashboardMetricBodySerializerWithCertainFieldsRemoved3ecd9dd5019f4ad79f4ceabb7ddfb19fRequest, options) => {
    return axios.post(`/api/agent_dashboard/count_transactions`, agentDashboardMetricBodySerializerWithCertainFieldsRemoved3ecd9dd5019f4ad79f4ceabb7ddfb19fRequest, options);
};
export const getCountTransactionsMutationOptions = (options) => {
    const mutationKey = ['countTransactions'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countTransactions(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count transactions
*/
export const useCountTransactions = (options) => {
    const mutationOptions = getCountTransactionsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Count transactions by status
*/
export const countTransactionsByStatus = (agentDashboardMetricBodySerializerWithCertainFieldsRemoved3ecd9dd5019f4ad79f4ceabb7ddfb19fRequest, options) => {
    return axios.post(`/api/agent_dashboard/count_transactions_by_status`, agentDashboardMetricBodySerializerWithCertainFieldsRemoved3ecd9dd5019f4ad79f4ceabb7ddfb19fRequest, options);
};
export const getCountTransactionsByStatusMutationOptions = (options) => {
    const mutationKey = ['countTransactionsByStatus'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countTransactionsByStatus(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count transactions by status
*/
export const useCountTransactionsByStatus = (options) => {
    const mutationOptions = getCountTransactionsByStatusMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Count transactions by third party
*/
export const countTransactionsByThirdParty = (agentDashboardMetricBodySerializerWithCertainFieldsRemoved3ecd9dd5019f4ad79f4ceabb7ddfb19fRequest, options) => {
    return axios.post(`/api/agent_dashboard/count_transactions_by_third_party`, agentDashboardMetricBodySerializerWithCertainFieldsRemoved3ecd9dd5019f4ad79f4ceabb7ddfb19fRequest, options);
};
export const getCountTransactionsByThirdPartyMutationOptions = (options) => {
    const mutationKey = ['countTransactionsByThirdParty'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countTransactionsByThirdParty(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count transactions by third party
*/
export const useCountTransactionsByThirdParty = (options) => {
    const mutationOptions = getCountTransactionsByThirdPartyMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get agent net
*/
export const getAgentNet = (agentDashboardMetricBodySerializerWithCertainFieldsRemoved3ecd9dd5019f4ad79f4ceabb7ddfb19fRequest, options) => {
    return axios.post(`/api/agent_dashboard/get_agent_net`, agentDashboardMetricBodySerializerWithCertainFieldsRemoved3ecd9dd5019f4ad79f4ceabb7ddfb19fRequest, options);
};
export const getGetAgentNetMutationOptions = (options) => {
    const mutationKey = ['getAgentNet'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return getAgentNet(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get agent net
*/
export const useGetAgentNet = (options) => {
    const mutationOptions = getGetAgentNetMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get total units
*/
export const getTotalUnits = (agentDashboardMetricBodySerializerWithCertainFieldsRemoved3ecd9dd5019f4ad79f4ceabb7ddfb19fRequest, options) => {
    return axios.post(`/api/agent_dashboard/get_total_units`, agentDashboardMetricBodySerializerWithCertainFieldsRemoved3ecd9dd5019f4ad79f4ceabb7ddfb19fRequest, options);
};
export const getGetTotalUnitsMutationOptions = (options) => {
    const mutationKey = ['getTotalUnits'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return getTotalUnits(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get total units
*/
export const useGetTotalUnits = (options) => {
    const mutationOptions = getGetTotalUnitsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get total volume
*/
export const getTotalVolume = (agentDashboardMetricBodySerializerWithCertainFieldsRemoved3ecd9dd5019f4ad79f4ceabb7ddfb19fRequest, options) => {
    return axios.post(`/api/agent_dashboard/get_total_volume`, agentDashboardMetricBodySerializerWithCertainFieldsRemoved3ecd9dd5019f4ad79f4ceabb7ddfb19fRequest, options);
};
export const getGetTotalVolumeMutationOptions = (options) => {
    const mutationKey = ['getTotalVolume'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return getTotalVolume(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get total volume
*/
export const useGetTotalVolume = (options) => {
    const mutationOptions = getGetTotalVolumeMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary List all sources
*/
export const listAllSources = (options) => {
    return axios.get(`/api/agent_dashboard/sources`, options);
};
export const getListAllSourcesQueryKey = () => {
    return [`/api/agent_dashboard/sources`];
};
export const getListAllSourcesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListAllSourcesQueryKey();
    const queryFn = ({ signal }) => listAllSources(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all sources
 */
export function useListAllSources(options) {
    const queryOptions = getListAllSourcesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary List transactions
 */
export const listTransactions = (paginatedAgentDashboardSearchTransactionsBodyRequest, options) => {
    return axios.post(`/api/agent_dashboard/transactions`, paginatedAgentDashboardSearchTransactionsBodyRequest, options);
};
export const getListTransactionsMutationOptions = (options) => {
    const mutationKey = ['listTransactions'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return listTransactions(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary List transactions
*/
export const useListTransactions = (options) => {
    const mutationOptions = getListTransactionsMutationOptions(options);
    return useMutation(mutationOptions);
};
