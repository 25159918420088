import * as React from "react";
import { Code, Comment, CopyAll, Mail } from "@mui/icons-material";
import { Divider, Link, ListItemIcon, MenuItem } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";
import streakIcon from "@app/assets/img/streak.png";
import CorePopover from "@app/common/CorePopover";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import useSession from "@app/hooks/useSession";
import { useDuplicateSellerLead, useDuplicateSellerLeadAsBuyerLead } from "@app/orval/api/seller-leads";
import { copyToClipboard, getFullName, getStreakLinkForBox } from "@app/util/Utils";
import CommentPopover from "../../../dashboards/agent-dashboard/components/pinned-boxes/CommentPopover";
import ActionsMenu from "../../components/box-view/ActionsMenu";
import EmailMenuSection from "../../components/box-view/ActionsMenu/EmailMenuSection";
import PhoneMenuSection from "../../components/box-view/ActionsMenu/PhoneMenuSection";
import PremierAgentMenuSection from "../../components/box-view/ActionsMenu/PremierAgentMenuSection";
import OpenLoopInDotloopMenuItem from "../../components/box-view/OpenLoopInDotloopMenuItem";
import { copyQFBLink, copyQFLLink } from "../utils";
const SellerLeadActionsMenu = (props) => {
    var _a;
    const { onCreateSellerContractSelected, sellerLead } = props;
    const ref = React.useRef(null);
    const [isCommentPopoverOpen, setIsCommentPopoverOpen] = React.useState(false);
    const openCommentPopover = React.useCallback(() => {
        setIsCommentPopoverOpen(true);
    }, []);
    const closeCommentPopover = React.useCallback(() => {
        setIsCommentPopoverOpen(false);
    }, []);
    const premierAgentId = sellerLead.fields["Premier Agent Contact ID"];
    const session = useSession();
    const canViewApiResponse = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"]);
    const navigate = useNavigate();
    const handleCopyQFLLink = React.useCallback(() => {
        copyQFLLink(sellerLead);
    }, [sellerLead]);
    const handleCopyQFBLink = React.useCallback(() => {
        copyQFBLink(sellerLead);
    }, [sellerLead]);
    const duplicateApi = useDuplicateSellerLead();
    const duplicate = React.useCallback(() => {
        duplicateApi
            .mutateAsync({ uid: sellerLead.uid })
            .then((result) => {
            navigate(`/sellers/${result.data.uid}`);
        })
            .catch(enqueueErrorSnackbar);
    }, [sellerLead.uid, duplicateApi, navigate]);
    const duplicateAsBuyerApi = useDuplicateSellerLeadAsBuyerLead();
    const createBuyerLead = React.useCallback(() => {
        duplicateAsBuyerApi
            .mutateAsync({ uid: sellerLead.uid })
            .then((result) => {
            navigate(`/buyers/${result.data.uid}`);
        })
            .catch(enqueueErrorSnackbar);
    }, [sellerLead.uid, duplicateAsBuyerApi, navigate]);
    const isCreatingLead = duplicateApi.isPending || duplicateAsBuyerApi.isPending;
    const copyLinkToLead = React.useCallback(() => {
        const url = new URL(window.location.href);
        url.pathname = `/a/sellers/${sellerLead.uid}`;
        url.search = "";
        url.hash = "";
        copyToClipboard(url.toString());
        enqueueSnackbar("Copied link to clipboard", { variant: "success" });
    }, [sellerLead.uid]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ActionsMenu, { ref: ref },
            React.createElement(MenuItem, { onClick: openCommentPopover },
                React.createElement(ListItemIcon, null,
                    React.createElement(Comment, null)),
                "Add a comment"),
            React.createElement(MenuItem, { onClick: handleCopyQFLLink },
                React.createElement(ListItemIcon, null,
                    React.createElement(CopyAll, null)),
                "Copy Questions for the Listing Link"),
            React.createElement(MenuItem, { onClick: handleCopyQFBLink },
                React.createElement(ListItemIcon, null,
                    React.createElement(CopyAll, null)),
                "Copy Questions for the Brag Link"),
            React.createElement(Divider, null),
            React.createElement(EmailMenuSection, { entityType: "Seller Lead", contacts: sellerLead.clients.map((client) => {
                    var _a;
                    return ({
                        name: getFullName(client.client.first_name, client.client.last_name),
                        email: (_a = client.client.email) !== null && _a !== void 0 ? _a : "",
                    });
                }), entityUids: [sellerLead.uid] }),
            React.createElement(Divider, null),
            React.createElement(PhoneMenuSection, { contacts: sellerLead.clients.map((client) => {
                    var _a;
                    return ({
                        name: getFullName(client.client.first_name, client.client.last_name),
                        phone: (_a = client.client.phone) !== null && _a !== void 0 ? _a : "",
                    });
                }) }),
            React.createElement(Divider, null),
            React.createElement(PremierAgentMenuSection, { premierAgentId: premierAgentId }),
            React.createElement(Divider, null),
            React.createElement(MenuItem, { component: Link, href: `/seller-leads/${sellerLead.uid}/mail-schedule`, target: "_blank" },
                React.createElement(ListItemIcon, null,
                    React.createElement(Mail, null)),
                "Open Mail Schedule"),
            sellerLead.streak_seller_leads_box_key && (React.createElement(MenuItem, { component: Link, href: getStreakLinkForBox(sellerLead.streak_seller_leads_box_key), target: "_blank" },
                React.createElement(ListItemIcon, null,
                    React.createElement("img", { height: 20, alt: "Streak", src: streakIcon })),
                "Open Streak box")),
            React.createElement(OpenLoopInDotloopMenuItem, { loopId: sellerLead.fields["Dotloop ID"] }),
            React.createElement(Divider, null),
            onCreateSellerContractSelected && (React.createElement(MenuItem, { onClick: onCreateSellerContractSelected }, "Start new contract")),
            React.createElement(MenuItem, { onClick: duplicate, disabled: isCreatingLead }, "Duplicate seller lead"),
            React.createElement(MenuItem, { onClick: createBuyerLead, disabled: isCreatingLead }, "Create buyer lead"),
            React.createElement(Divider, null),
            React.createElement(MenuItem, { onClick: copyLinkToLead },
                React.createElement(ListItemIcon, null,
                    React.createElement(CopyAll, { fontSize: "small" })),
                "Copy link to this lead"),
            canViewApiResponse && (React.createElement(MenuItem, { component: Link, href: `/api/seller_leads/${sellerLead.uid}`, target: "_blank" },
                React.createElement(ListItemIcon, null,
                    React.createElement(Code, null)),
                "View API Response"))),
        isCommentPopoverOpen && (React.createElement(CorePopover, { open: true, anchorEl: ref.current },
            React.createElement(CommentPopover, { onCancel: closeCommentPopover, onSubmit: closeCommentPopover, entityUid: sellerLead.uid })))));
};
export default SellerLeadActionsMenu;
