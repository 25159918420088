import * as React from "react";
import { Code, Comment, Mail } from "@mui/icons-material";
import { Divider, Link, ListItemIcon, MenuItem } from "@mui/material";
import streakIcon from "@app/assets/img/streak.png";
import CorePopover from "@app/common/CorePopover";
import useSession from "@app/hooks/useSession";
import { getFullName, getStreakLinkForBox } from "@app/util/Utils";
import CommentPopover from "../../../dashboards/agent-dashboard/components/pinned-boxes/CommentPopover";
import ActionsMenu from "../../components/box-view/ActionsMenu";
import EmailMenuSection from "../../components/box-view/ActionsMenu/EmailMenuSection";
import PhoneMenuSection from "../../components/box-view/ActionsMenu/PhoneMenuSection";
import PremierAgentMenuSection from "../../components/box-view/ActionsMenu/PremierAgentMenuSection";
import OpenLoopInDotloopMenuItem from "../../components/box-view/OpenLoopInDotloopMenuItem";
const SellerContractActionsMenu = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { sellerContract } = props;
    const ref = React.useRef(null);
    const [isCommentPopoverOpen, setIsCommentPopoverOpen] = React.useState(false);
    const openCommentPopover = React.useCallback(() => {
        setIsCommentPopoverOpen(true);
    }, []);
    const closeCommentPopover = React.useCallback(() => {
        setIsCommentPopoverOpen(false);
    }, []);
    const premierAgentId = (_a = sellerContract.seller_lead) === null || _a === void 0 ? void 0 : _a.fields["Premier Agent Contact ID"];
    const session = useSession();
    const canViewApiResponse = (_b = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.hasRole(["Superadmin", "Admin"]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ActionsMenu, { ref: ref },
            React.createElement(MenuItem, { onClick: openCommentPopover },
                React.createElement(ListItemIcon, null,
                    React.createElement(Comment, null)),
                "Add a comment"),
            React.createElement(Divider, null),
            React.createElement(EmailMenuSection, { entityType: "Listing", contacts: (_d = (_c = sellerContract.seller_lead) === null || _c === void 0 ? void 0 : _c.clients.map((client) => {
                    var _a;
                    return ({
                        name: getFullName(client.client.first_name, client.client.last_name),
                        email: (_a = client.client.email) !== null && _a !== void 0 ? _a : "",
                    });
                })) !== null && _d !== void 0 ? _d : [], entityUids: [sellerContract.uid] }),
            React.createElement(Divider, null),
            React.createElement(PhoneMenuSection, { contacts: (_f = (_e = sellerContract.seller_lead) === null || _e === void 0 ? void 0 : _e.clients.map((client) => {
                    var _a;
                    return ({
                        name: getFullName(client.client.first_name, client.client.last_name),
                        phone: (_a = client.client.phone) !== null && _a !== void 0 ? _a : "",
                    });
                })) !== null && _f !== void 0 ? _f : [] }),
            React.createElement(PremierAgentMenuSection, { premierAgentId: premierAgentId }),
            React.createElement(Divider, null),
            React.createElement(MenuItem, { component: Link, href: `/seller-contracts/${sellerContract.uid}/mail-schedule`, target: "_blank" },
                React.createElement(ListItemIcon, null,
                    React.createElement(Mail, null)),
                "Open Mail Schedule"),
            sellerContract.streak_luc_box_key && (React.createElement(MenuItem, { component: Link, href: getStreakLinkForBox(sellerContract.streak_luc_box_key), target: "_blank" },
                React.createElement(ListItemIcon, null,
                    React.createElement("img", { height: 20, alt: "Streak", src: streakIcon })),
                "Open Streak box")),
            React.createElement(OpenLoopInDotloopMenuItem, { loopId: (_g = sellerContract.seller_lead) === null || _g === void 0 ? void 0 : _g.fields["Dotloop ID"] }),
            React.createElement(Divider, null),
            canViewApiResponse && React.createElement(Divider, null),
            canViewApiResponse && (React.createElement(MenuItem, { component: Link, href: `/api/seller_contracts/${sellerContract.uid}`, target: "_blank" },
                React.createElement(ListItemIcon, null,
                    React.createElement(Code, null)),
                "View API Response"))),
        isCommentPopoverOpen && (React.createElement(CorePopover, { open: true, anchorEl: ref.current },
            React.createElement(CommentPopover, { onCancel: closeCommentPopover, onSubmit: closeCommentPopover, 
                // @ts-expect-error: need this in order for this to work from the Overview page
                entityUid: (_j = (_h = sellerContract.seller_lead) === null || _h === void 0 ? void 0 : _h.uid) !== null && _j !== void 0 ? _j : sellerContract.lead_uid })))));
};
export default SellerContractActionsMenu;
