import * as React from "react";
import { Box, Chip, Divider, Grid2, List, ListItemButton, ListItemText, Typography } from "@mui/material";
import numeral from "numeral";
import RouterLink from "@app/common/RouterLink";
import useLoading from "@app/hooks/useLoading";
import { useListOfficeExclusiveSellerLeads } from "@app/orval/api/seller-leads";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import StreetViewImage from "../buyer-leads/components/StreetViewImage";
const OfficeExclusive = () => {
    var _a, _b;
    const searchSellerLeadsApi = useListOfficeExclusiveSellerLeads(REFETCH_CACHE_PARAMS);
    const sellerLeads = (_b = (_a = searchSellerLeadsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const { isLoading, loadingComponent } = useLoading({
        items: [
            {
                label: "Loading seller leads",
                queryResult: searchSellerLeadsApi,
            },
        ],
    });
    if (isLoading) {
        return loadingComponent;
    }
    return (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", style: { maxHeight: "100%", overflow: "hidden" } },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(Typography, { variant: "h6" }, "Office Exclusive"))),
        sellerLeads && sellerLeads.length > 0 && (React.createElement(React.Fragment, null,
            React.createElement(Grid2, null,
                React.createElement(Divider, null)),
            React.createElement(Grid2, null,
                React.createElement(List, { dense: true }, sellerLeads.map((sellerLead, i) => {
                    const address = [
                        sellerLead.fields["Street #"],
                        sellerLead.fields["Street Name"],
                        sellerLead.fields["City"],
                        sellerLead.fields["State"],
                        sellerLead.fields["Zip"],
                    ]
                        .filter((o) => o)
                        .join(" ");
                    return (React.createElement(ListItemButton, { key: sellerLead.uid, component: RouterLink, to: `/sellers/${sellerLead.uid}`, divider: i < sellerLeads.length - 1 },
                        React.createElement(ListItemText, { primary: React.createElement(Grid2, { container: true, direction: "row", wrap: "nowrap", spacing: 2 },
                                React.createElement(Grid2, null,
                                    React.createElement(StreetViewImage, { address: address, ImageProps: { style: { maxHeight: "80px" } } })),
                                React.createElement(Grid2, null,
                                    React.createElement(Grid2, { container: true, direction: "column" },
                                        sellerLead.fields["Property Type"] != null && (React.createElement(Grid2, null,
                                            React.createElement(Chip, { size: "small", label: sellerLead.fields["Property Type"] }))),
                                        React.createElement(Grid2, null,
                                            React.createElement(Typography, { variant: "body2" }, address)),
                                        sellerLead.fields["List Price"] != null && (React.createElement(Grid2, { container: true, direction: "row", wrap: "nowrap", spacing: 1 },
                                            React.createElement(Grid2, null,
                                                React.createElement(Typography, { variant: "body2", fontWeight: "bold" }, "List Price:")),
                                            React.createElement(Grid2, null,
                                                React.createElement(Typography, { variant: "body2" }, numeral(sellerLead.fields["List Price"]).format("$0,0"))))),
                                        sellerLead.fields["SqFt"] != null && (React.createElement(Grid2, { container: true, direction: "row", wrap: "nowrap", spacing: 1 },
                                            React.createElement(Grid2, null,
                                                React.createElement(Typography, { variant: "body2", fontWeight: "bold" }, "SqFt:")),
                                            React.createElement(Grid2, null,
                                                React.createElement(Typography, { variant: "body2" }, numeral(sellerLead.fields["SqFt"]).format("0,0"))))),
                                        sellerLead.fields["Beds"] != null && (React.createElement(Grid2, { container: true, direction: "row", wrap: "nowrap", spacing: 1 },
                                            React.createElement(Grid2, null,
                                                React.createElement(Typography, { variant: "body2", fontWeight: "bold" }, "Beds:")),
                                            React.createElement(Grid2, null,
                                                React.createElement(Typography, { variant: "body2" }, numeral(sellerLead.fields["Beds"]).format("0,0"))))),
                                        sellerLead.fields["Baths"] != null && (React.createElement(Grid2, { container: true, direction: "row", wrap: "nowrap", spacing: 1 },
                                            React.createElement(Grid2, null,
                                                React.createElement(Typography, { variant: "body2", fontWeight: "bold" }, "Bath")),
                                            React.createElement(Grid2, null,
                                                React.createElement(Typography, { variant: "body2" }, numeral(sellerLead.fields["Baths"]).format("0,0.0"))))),
                                        sellerLead.fields["Garage"] != null && (React.createElement(Grid2, { container: true, direction: "row", wrap: "nowrap", spacing: 1 },
                                            React.createElement(Grid2, null,
                                                React.createElement(Typography, { variant: "body2", fontWeight: "bold" }, "Garage")),
                                            React.createElement(Grid2, null,
                                                React.createElement(Typography, { variant: "body2" }, sellerLead.fields["Garage"])))),
                                        sellerLead.fields["Buy Side Commission %"] != null && (React.createElement(Grid2, { container: true, direction: "row", wrap: "nowrap", spacing: 1 },
                                            React.createElement(Grid2, null,
                                                React.createElement(Typography, { variant: "body2", fontWeight: "bold" }, "Buy Side Commission Percent")),
                                            React.createElement(Grid2, null,
                                                React.createElement(Typography, { variant: "body2" }, numeral(sellerLead.fields["Buy Side Commission %"] * 100).format("0,0.000%")))))))) })));
                })))))));
};
export default OfficeExclusive;
