import * as React from "react";
import { AddOutlined } from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";
import dayjs_ from "@app/common/dayjs";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useSession from "@app/hooks/useSession";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { useListFields } from "@app/orval/api/field-definitions";
import { countSellerLeads, countSellerLeadsByStage, downloadSellerLeads, importSellerLeads, searchSellerLeads, useAddSellerLead, useBulkDeleteSellerLeads, } from "@app/orval/api/seller-leads";
import { FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA, REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { doSetsIntersect } from "@app/util/Utils";
import SellerLeadActionsMenu from "./components/SellerLeadActionsMenu";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
import { StandardSmallCell } from "../components/spreadsheet-view/components";
import Spreadsheet from "../components/spreadsheet-view/Spreadsheet";
import { SpreadsheetContext } from "../components/spreadsheet-view/SpreadsheetContext";
import { agentCellValueGetter, clientCellValueFormatter, collaboratorCellValueFormatter, renderAgentsCell, renderClientsCell, renderCollaboratorsCell, } from "../components/spreadsheet-view/table-cells";
import { DAYS_IN_STAGE_COLUMN, PRODUCER_LAST_UPDATED_TIMESTAMP_COLUMN } from "../components/spreadsheet-view/utils";
import useClients from "../hooks/useClients";
import useCollaborators from "../hooks/useCollaborators";
import useStages from "../hooks/useStages";
import useTeamAdminPermissions from "../users/useTeamAdminPermissions";
import { groupBy, indexBy } from "../utils/utils";
const SellerLeads = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    const stage = (_a = props.stage) !== null && _a !== void 0 ? _a : undefined;
    const limit = (_b = props.limit) !== null && _b !== void 0 ? _b : 50;
    const offset = (_c = props.offset) !== null && _c !== void 0 ? _c : 0;
    const showNewsfeed = (_d = props.showNewsfeed) !== null && _d !== void 0 ? _d : false;
    const { search, view } = props;
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const errorDialog = useErrorDialog();
    const session = useSession();
    const context = React.useContext(SpreadsheetContext);
    const bulkDeleteApi = useBulkDeleteSellerLeads();
    const { onSellerLeadStagesSaved, sellerLeadStages, setSellerLeadStage } = useStages();
    const { addClientForSellerLead, canEditClientsCells, clientsDialogParams, closeClientsDialog, isClientsDialogOpen, openClientsDialog, removeClientForSellerLead, reorderClientsForSellerLead, setClientsDialogParams, } = useClients();
    const { addCollaboratorForSellerLead, canEditCollaboratorsCells, closeCollaboratorsDialog, collaboratorsDialogParams, isCollaboratorsDialogOpen, openCollaboratorsDialog, removeCollaboratorForSellerLead, setCollaboratorsDialogParams, updateCollaboratorForSellerLead, } = useCollaborators();
    const { usersInMyPurview } = useTeamAdminPermissions();
    const userUidsInMyPurview = React.useMemo(() => {
        return usersInMyPurview ? new Set(usersInMyPurview.map((x) => x.uid)) : null;
    }, [usersInMyPurview]);
    const requestData = React.useMemo(() => {
        var _a;
        return pickBy({ search, view, stage_uids: stage ? [stage] : undefined, filter: (_a = context === null || context === void 0 ? void 0 : context.filter) !== null && _a !== void 0 ? _a : undefined }, (val) => val !== undefined);
    }, [context === null || context === void 0 ? void 0 : context.filter, search, stage, view]);
    const paginatedRequestData = React.useMemo(() => {
        return Object.assign(Object.assign({}, requestData), { limit, offset });
    }, [requestData, limit, offset]);
    const searchSellerLeadsApi = usePostQuery(searchSellerLeads, paginatedRequestData, FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA);
    const sellerLeads = (_f = (_e = searchSellerLeadsApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const countSellerLeadsApi = usePostQuery(countSellerLeads, requestData, FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA);
    const count = (_h = (_g = countSellerLeadsApi.data) === null || _g === void 0 ? void 0 : _g.data.count) !== null && _h !== void 0 ? _h : null;
    const totalSellerLeadsApi = usePostQuery(countSellerLeads, {}, FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA);
    const total = (_k = (_j = totalSellerLeadsApi.data) === null || _j === void 0 ? void 0 : _j.data.count) !== null && _k !== void 0 ? _k : null;
    const countByStageApi = usePostQuery(countSellerLeadsByStage, omit(requestData, "stage_uids"), FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA);
    const countByStage = (_m = (_l = countByStageApi.data) === null || _l === void 0 ? void 0 : _l.data) !== null && _m !== void 0 ? _m : null;
    const items = React.useMemo(() => {
        return sellerLeads
            ? sellerLeads.map((item) => (Object.assign(Object.assign({}, item), { collaborators: item.collaborators, agents: item.agents, clients: item.clients, producer: item.agents.filter((agent) => agent.type === "Producer"), co_agent: item.agents.filter((agent) => agent.type === "Co-Agent"), listing_coordinators: item.agents.filter((agent) => agent.type === "Listing Coordinator") })))
            : null;
    }, [sellerLeads]);
    const countByStage_allStages = React.useMemo(() => {
        return sellerLeadStages && countByStage
            ? sellerLeadStages.map((s) => { var _a, _b; return ({ stage: s, count: (_b = (_a = countByStage.find((c) => { var _a; return ((_a = c.stage) === null || _a === void 0 ? void 0 : _a.uid) === s.uid; })) === null || _a === void 0 ? void 0 : _a.count) !== null && _b !== void 0 ? _b : 0 }); })
            : null;
    }, [sellerLeadStages, countByStage]);
    const updateSearch = useUpdateSearch();
    const countByGroup = React.useMemo(() => {
        return countByStage_allStages
            ? countByStage_allStages.map((item) => {
                var _a, _b, _c;
                return ({
                    group: {
                        uid: (_a = item.stage.uid) !== null && _a !== void 0 ? _a : "",
                        name: (_b = item.stage.name) !== null && _b !== void 0 ? _b : "Not Set",
                        color: (_c = sellerLeadStages === null || sellerLeadStages === void 0 ? void 0 : sellerLeadStages.find((s) => s.uid === item.stage.uid)) === null || _c === void 0 ? void 0 : _c.color,
                        onClick: () => {
                            var _a;
                            updateSearch("stage", stage === item.stage.uid ? null : ((_a = item.stage.uid) !== null && _a !== void 0 ? _a : null), "offset", null);
                        },
                        isSelected: stage === item.stage.uid,
                    },
                    count: item.count,
                });
            })
            : null;
    }, [countByStage_allStages, stage, sellerLeadStages, updateSearch]);
    const listFieldsApi = useListFields({ entity_type: "Seller Lead" }, REFETCH_CACHE_PARAMS);
    const fields = (_p = (_o = listFieldsApi.data) === null || _o === void 0 ? void 0 : _o.data) !== null && _p !== void 0 ? _p : null;
    const sellerLeadsGroupedByUid = React.useMemo(() => {
        return sellerLeads ? groupBy(sellerLeads, "uid") : null;
    }, [sellerLeads]);
    const itemsByUid = React.useMemo(() => {
        return items ? groupBy(items, "uid") : null;
    }, [items]);
    const itemsIndexedByUid = React.useMemo(() => {
        return items ? indexBy(items, "uid") : null;
    }, [items]);
    const stagesByUid = React.useMemo(() => {
        return sellerLeadStages ? groupBy(sellerLeadStages, "uid") : null;
    }, [sellerLeadStages]);
    const createItemApi = useAddSellerLead();
    const createItem = React.useCallback(() => {
        var _a;
        createItemApi
            .mutateAsync({
            data: {
                fields: {},
                collaborators: ((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.uid)
                    ? [
                        {
                            user_uid: session.viewingAsUser.uid,
                            can_edit: true,
                            can_run_mail_schedule: true,
                            is_mail_schedule_owner: true,
                            should_receive_notifications: true,
                        },
                    ]
                    : undefined,
            },
        })
            .then((result) => {
            navigate(`/sellers/${result.data.uid}`);
        })
            .catch(errorDialog);
    }, [createItemApi, errorDialog, navigate, (_q = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _q === void 0 ? void 0 : _q.uid]);
    const onBulkUpdate = React.useCallback((items_) => {
        queryClient.setQueryData(searchSellerLeadsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: prev.data.map((item) => {
                    const updatedItem = items_.find((x) => x.uid === item.uid);
                    return updatedItem !== null && updatedItem !== void 0 ? updatedItem : item;
                }) });
        });
    }, [queryClient, searchSellerLeadsApi.queryKey]);
    const bulkDelete = React.useCallback((selectedUids) => {
        queryClient.setQueryData(searchSellerLeadsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: prev.data.filter((item) => !selectedUids.includes(item.uid)) });
        });
        queryClient.setQueryData(countSellerLeadsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: { count: prev.data.count - selectedUids.length } });
        });
        queryClient.setQueryData(countByStageApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: prev.data.map((item) => (Object.assign(Object.assign({}, item), { count: item.count - selectedUids.filter((uid) => { var _a, _b, _c; return ((_b = (_a = itemsByUid === null || itemsByUid === void 0 ? void 0 : itemsByUid[uid]) === null || _a === void 0 ? void 0 : _a.stage) === null || _b === void 0 ? void 0 : _b.uid) === ((_c = item.stage) === null || _c === void 0 ? void 0 : _c.uid); }).length }))) });
        });
        queryClient.setQueryData(totalSellerLeadsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: { count: prev.data.count - selectedUids.length } });
        });
        bulkDeleteApi
            .mutateAsync({ data: { uids: selectedUids } })
            .then(() => {
            enqueueSnackbar(`${selectedUids.length} seller leads deleted`, { variant: "success" });
        })
            .catch(enqueueErrorSnackbar);
    }, [
        bulkDeleteApi,
        countSellerLeadsApi.queryKey,
        countByStageApi.queryKey,
        itemsByUid,
        queryClient,
        searchSellerLeadsApi.queryKey,
        totalSellerLeadsApi.queryKey,
    ]);
    const refresh = React.useCallback(() => {
        searchSellerLeadsApi.refetch();
        countByStageApi.refetch();
        countSellerLeadsApi.refetch();
    }, [searchSellerLeadsApi, countByStageApi, countSellerLeadsApi]);
    const getPOSTApiEndpoint = React.useCallback((row) => {
        return `/api/seller_leads/${row.uid}`;
    }, []);
    const getMailMergeEmailAddressesForItem = React.useCallback((row) => {
        return row.clients
            .map((x) => x.client.email)
            .filter((x) => x)
            .map((x) => x);
    }, []);
    const getDetailURL = React.useCallback((row) => {
        const item = itemsByUid ? itemsByUid[row] : null;
        if (item) {
            return `/sellers/${item.uid}`;
        }
        return "";
    }, [itemsByUid]);
    const nonStandardColumns = [
        {
            field: "collaborators",
            headerName: "Collaborators",
            valueFormatter: collaboratorCellValueFormatter,
            renderCell: renderCollaboratorsCell,
            renderEditCell: renderCollaboratorsCell,
            editable: canEditCollaboratorsCells,
        },
        {
            field: "clients",
            headerName: "Clients",
            valueFormatter: clientCellValueFormatter,
            renderCell: renderClientsCell,
            renderEditCell: renderClientsCell,
            editable: canEditClientsCells,
        },
        {
            field: "producer",
            headerName: "Producer",
            valueGetter: agentCellValueGetter,
            renderCell: renderAgentsCell,
            editable: false,
        },
        {
            field: "co_agent",
            headerName: "Co-Agent",
            valueGetter: agentCellValueGetter,
            renderCell: renderAgentsCell,
            editable: false,
        },
        {
            field: "listing_coordinators",
            headerName: "Listing Coordinator",
            valueGetter: agentCellValueGetter,
            renderCell: renderAgentsCell,
            editable: false,
        },
        DAYS_IN_STAGE_COLUMN,
        PRODUCER_LAST_UPDATED_TIMESTAMP_COLUMN,
    ];
    const onCellEditStart = React.useCallback((params) => {
        var _a;
        const sellerLead = (_a = sellerLeadsGroupedByUid[params.id]) !== null && _a !== void 0 ? _a : null;
        console.log("EDITING COL:", params.field);
        if (!sellerLead) {
            return;
        }
        if (params.field === "clients") {
            setClientsDialogParams({ boxUid: sellerLead.uid, existingClients: sellerLead.clients });
            openClientsDialog();
        }
        if (params.field === "collaborators") {
            console.log("OPEN COLLABORATORS DIALOG", sellerLead);
            setCollaboratorsDialogParams({
                boxUid: sellerLead.uid,
                existingCollaborators: sellerLead.collaborators,
            });
            openCollaboratorsDialog();
        }
    }, [
        openClientsDialog,
        openCollaboratorsDialog,
        sellerLeadsGroupedByUid,
        setClientsDialogParams,
        setCollaboratorsDialogParams,
    ]);
    const onImportFileSelected = React.useCallback((fileContent) => {
        console.log({ fileContent });
        const loadingSnackbar = enqueueSnackbar("Importing seller leads...", { variant: "info", persist: true });
        importSellerLeads({ content: fileContent })
            .then(() => {
            enqueueSnackbar("Seller leads imported successfully", { variant: "success" });
            refresh();
        })
            .catch((error) => {
            enqueueErrorSnackbar(error);
        })
            .finally(() => {
            closeSnackbar(loadingSnackbar);
        });
    }, [refresh]);
    const onFieldChanged = React.useCallback((params) => {
        const { field, row, value } = params;
        const fullIndx = itemsIndexedByUid[row];
        queryClient.setQueryData(searchSellerLeadsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: [
                    ...prev.data.slice(0, fullIndx),
                    Object.assign(Object.assign({}, prev.data[fullIndx]), { fields: Object.assign(Object.assign({}, prev.data[fullIndx].fields), { [field]: value }) }),
                    ...prev.data.slice(fullIndx + 1, prev.data.length),
                ] });
        });
    }, [itemsIndexedByUid, queryClient, searchSellerLeadsApi.queryKey]);
    const onFieldWillSave = onFieldChanged;
    const onFieldSaved = React.useCallback((params) => {
        const { response, row } = params;
        const fullIndx = itemsIndexedByUid[row];
        queryClient.setQueryData(searchSellerLeadsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: [...prev.data.slice(0, fullIndx), response.data, ...prev.data.slice(fullIndx + 1, prev.data.length)] });
        });
    }, [itemsIndexedByUid, queryClient, searchSellerLeadsApi.queryKey]);
    const page = React.useMemo(() => {
        return Math.floor(offset / limit) + 1;
    }, [offset, limit]);
    const renderActionsColumn = React.useCallback((params) => {
        return React.createElement(SellerLeadActionsMenu, { sellerLead: params.row });
    }, []);
    const renderListItem = React.useCallback((item) => {
        return (React.createElement(StandardSmallCell, { name: item.name, stage: stagesByUid && item.stage ? stagesByUid[item.stage.uid] : undefined, 
            // @ts-expect-error: readonly
            collaborators: item.collaborators, link: `/sellers/${item.uid}`, allLinks: items === null || items === void 0 ? void 0 : items.map((x) => `/sellers/${x.uid}`) }));
    }, [items, stagesByUid]);
    const isCellEditable = React.useCallback((params) => {
        var _a, _b;
        const item = params.row;
        const collaboratorsWhoCanEdit = new Set(item.collaborators.filter((x) => x.can_edit).map((x) => x.user.uid));
        const agentUids = new Set(item.agents.map((x) => x.agent.uid));
        return (!!(userUidsInMyPurview && userUidsInMyPurview.size > 1 && doSetsIntersect(agentUids, userUidsInMyPurview)) ||
            collaboratorsWhoCanEdit.has((_b = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : ""));
    }, [(_r = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _r === void 0 ? void 0 : _r.uid, userUidsInMyPurview]);
    const onRowStageUpdateBegin = React.useCallback((buyerLeadUid, stageUid) => {
        console.log("Set initially to stage: ", stageUid);
        queryClient.setQueryData(searchSellerLeadsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            const fullIndx = prev.data.findIndex((x) => x.uid === buyerLeadUid);
            return Object.assign(Object.assign({}, prev), { data: [
                    ...prev.data.slice(0, fullIndx),
                    Object.assign(Object.assign({}, prev.data[fullIndx]), { stage: Object.assign(Object.assign({}, stagesByUid[stageUid]), { updated_at: dayjs_().format("YYYY-MM-DDThh:mm:ssZ") }) }),
                    ...prev.data.slice(fullIndx + 1, prev.data.length),
                ] });
        });
    }, [queryClient, searchSellerLeadsApi.queryKey, stagesByUid]);
    const onRowUpdateError = React.useCallback((lead) => () => {
        console.log("Reset state to lead with stage:", lead.stage);
        queryClient.setQueryData(searchSellerLeadsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: prev.data.map((item) => {
                    if (item.uid === lead.uid) {
                        return lead;
                    }
                    return item;
                }) });
        });
    }, [queryClient, searchSellerLeadsApi.queryKey]);
    const onRowUpdate = React.useCallback((result) => {
        queryClient.setQueryData(searchSellerLeadsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: prev.data.map((item) => (item.uid === result.data.uid ? result.data : item)) });
        });
    }, [queryClient, searchSellerLeadsApi.queryKey]);
    const onRowStageChange = React.useCallback((params) => {
        const { row, stageUid } = params;
        const sellerLead = sellerLeads === null || sellerLeads === void 0 ? void 0 : sellerLeads.find((x) => x.uid === row);
        if (sellerLead) {
            setSellerLeadStage(row, stageUid, {
                onUpdateBegin: onRowStageUpdateBegin,
                onError: onRowUpdateError(sellerLead),
                onUpdate: onRowUpdate,
            });
        }
    }, [onRowStageUpdateBegin, onRowUpdate, onRowUpdateError, sellerLeads, setSellerLeadStage]);
    const onRowClientsUpdate = React.useCallback((params) => {
        var _a;
        const { rowUid, updateParams } = params;
        const sellerLead = (_a = sellerLeadsGroupedByUid[rowUid]) !== null && _a !== void 0 ? _a : null;
        console.log("UPDATE CLIENTS FOR ROW:", rowUid, updateParams);
        if (sellerLead) {
            if (updateParams.contact_uid || updateParams.contact_data) {
                addClientForSellerLead(rowUid, updateParams, { onUpdate: onRowUpdate });
            }
        }
        else {
            enqueueSnackbar(`Unable to find Buyer Lead with uid: ${rowUid}`, { variant: "error" });
        }
        closeClientsDialog();
    }, [addClientForSellerLead, closeClientsDialog, onRowUpdate, sellerLeadsGroupedByUid]);
    const onRowRemoveClient = React.useCallback((params) => {
        var _a;
        const { clientUid, rowUid } = params;
        const sellerLead = (_a = sellerLeadsGroupedByUid[rowUid]) !== null && _a !== void 0 ? _a : null;
        console.log("REMOVE CLIENT FOR ROW:", rowUid, clientUid);
        if (sellerLead) {
            removeClientForSellerLead(rowUid, clientUid, { onRemove: onRowUpdate });
        }
        else {
            enqueueSnackbar(`Unable to find Buyer Lead with uid: ${rowUid}`, { variant: "error" });
        }
    }, [onRowUpdate, removeClientForSellerLead, sellerLeadsGroupedByUid]);
    const onRowReorderClients = React.useCallback((params) => {
        const { clientUids, rowUid } = params;
        console.log("REORDER CLIENTS FOR ROW:", rowUid, clientUids);
        reorderClientsForSellerLead({ sellerLeadUid: rowUid, clientUids });
    }, [reorderClientsForSellerLead]);
    const onRowCollaboratorUpdate = React.useCallback((params) => {
        const { collaboratorUid, rowUid, updateParams } = params;
        const sellerLead = sellerLeads === null || sellerLeads === void 0 ? void 0 : sellerLeads.find((x) => x.uid === rowUid);
        console.log("UPDATE COLLABORATOR FOR ROW:", rowUid, updateParams);
        if (sellerLead) {
            if (collaboratorUid) {
                updateCollaboratorForSellerLead(rowUid, collaboratorUid, updateParams, { onUpdate: onRowUpdate });
            }
            else {
                addCollaboratorForSellerLead(rowUid, updateParams, { onSaveComplete: onRowUpdate });
            }
        }
        else {
            enqueueSnackbar(`Unable to find Seller Lead with uid: ${rowUid}`, { variant: "error" });
        }
        closeCollaboratorsDialog();
    }, [sellerLeads, closeCollaboratorsDialog, updateCollaboratorForSellerLead, onRowUpdate, addCollaboratorForSellerLead]);
    const onRowRemoveCollaborator = React.useCallback((params) => {
        const { collaboratorUid, rowUid } = params;
        const sellerLead = sellerLeads === null || sellerLeads === void 0 ? void 0 : sellerLeads.find((x) => x.uid === rowUid);
        console.log("REMOVE COLLABORATOR FOR ROW:", rowUid, collaboratorUid);
        if (sellerLead) {
            removeCollaboratorForSellerLead(rowUid, collaboratorUid, { onRemove: onRowUpdate });
        }
        else {
            enqueueSnackbar(`Unable to find Seller Lead with uid: ${rowUid}`, { variant: "error" });
        }
    }, [onRowUpdate, removeCollaboratorForSellerLead, sellerLeads]);
    return (React.createElement(Spreadsheet, { title: "Seller Leads", fields: fields, stages: sellerLeadStages, view: view, countByGroup: countByGroup !== null && countByGroup !== void 0 ? countByGroup : [], items: items, search: search, nonStandardColumns: nonStandardColumns, getPOSTApiEndpoint: getPOSTApiEndpoint, renderActionsColumn: renderActionsColumn, getMailMergeEmailAddressesForItem: getMailMergeEmailAddressesForItem, getDetailURL: getDetailURL, entityType: "Seller Lead", rowsPerPage: limit, count: count !== null && count !== void 0 ? count : null, total: total !== null && total !== void 0 ? total : null, page: page, onRefresh: refresh, onStagesSaved: onSellerLeadStagesSaved, onCellEditStart: onCellEditStart, onFieldChanged: onFieldChanged, onFieldWillSave: onFieldWillSave, onFieldSaved: onFieldSaved, onRowStageChange: onRowStageChange, onRowClientChange: onRowClientsUpdate, onRowReorderClients: onRowReorderClients, onRowRemoveClient: onRowRemoveClient, isClientsDialogOpen: isClientsDialogOpen, onClientsDialogClose: closeClientsDialog, clientsDialogParams: clientsDialogParams !== null && clientsDialogParams !== void 0 ? clientsDialogParams : undefined, onRowCollaboratorChange: onRowCollaboratorUpdate, onRowRemoveCollaborator: onRowRemoveCollaborator, isCollaboratorsDialogOpen: isCollaboratorsDialogOpen, onCollaboratorsDialogClose: closeCollaboratorsDialog, collaboratorsDialogParams: collaboratorsDialogParams !== null && collaboratorsDialogParams !== void 0 ? collaboratorsDialogParams : undefined, renderListItem: renderListItem, slots: {
            addButton: (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Add seller lead") },
                React.createElement("span", null,
                    React.createElement(IconButton, { onClick: createItem, tabIndex: -1, disabled: createItemApi.isPending, color: "primary" },
                        React.createElement(AddOutlined, null))))),
        }, canEditStages: true, onImportFileSelected: ((_s = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _s === void 0 ? void 0 : _s.hasRole(["Superadmin", "Admin"])) ? onImportFileSelected : undefined, downloadFunc: downloadSellerLeads, downloadFilename: "seller_leads.csv", loadingPercentComplete: undefined, isLoading: searchSellerLeadsApi.isFetching, showNewsfeed: showNewsfeed, error: (_u = (_t = searchSellerLeadsApi.error) !== null && _t !== void 0 ? _t : countSellerLeadsApi.error) !== null && _u !== void 0 ? _u : countByStageApi.error, onBulkUpdateStages: onBulkUpdate, onBulkDelete: ((_v = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _v === void 0 ? void 0 : _v.hasRole(["Superadmin", "Admin"])) ? bulkDelete : undefined, isCellEditable: isCellEditable }));
};
export default SellerLeads;
